import React from "react";
import {Link, withRouter} from "react-router-dom";

class FooterUpper extends React.Component{
    render() {
        return( <div>
            <div className="row bg-light">
                <div className="order-2 col-xl-5 col-lg-4 col-12 mx-auto p-0" id="bg-articles">
                    <div className="bg-picture-container p-0">
                        <picture>
                            <source media="(max-width:568px)" type="image/webp"
                                    srcSet="/fbm-data/images/layout/banner/bg-articles-m.webp"/>
                            <source type="image/webp" srcSet="/fbm-data/images/layout/banner/bg-articles.webp"/>
                            <img src="/fbm-data/images/layout/banner/bg-articles.jpg" className="bg-picture"
                                 alt="articles background"/>
                        </picture>
                    </div>
                </div>
                <div className="order-1 col-xl-7 col-lg-8 col-12 align-self-center text-left text-lg-right mx-auto p-5">
                    <div className="p-0 p-lg-5">
                        <h2 className="med text-primary text-uppercase mb-3 pb-0">
						<span className="f-second sml text-gray">
							Latest Articles &amp; Tips on
						</span>
                            <br/>
                            Home Remodeling
                        </h2>
                        <ul className="list-group list-group-flush mb-0 pb-0">

                            <li className="list-group-item">
                                <Link to="/roofing-contractor-tips/best-roof-types-for-new-jersey-homes">Best Roof Types For
                                    New Jersey Homes</Link>
                                <p className="mt-2 mb-0">Whether you're taking on your first major renovation on your
                                    starter home or finally getting around to making your established Paramus home the home
                                    of your dreams, there are a lot of roof types on t [&hellip;]</p>
                            </li>

                            <li className="list-group-item">
                                <Link to="/roofing-contractor-tips/five-signs-you-need-a-new-roof">Five Signs You Need A New
                                    Roof</Link>
                                <p className="mt-2 mb-0">You work hard to keep a roof over your head, but have you given
                                    that roof a close look lately? Over time, even the best roofing materials can start to
                                    lose their strength and durability as they near [&hellip;]</p>
                            </li>

                        </ul>
                        <div className="mt-3">
                            <Link to="/roofing-contractor-tips" className="btn btn-more">Read More</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row bg-white">
                <div className="col-xl-8 col-lg-10 col-12 mx-auto p-5">
                    <div className="p-0 p-lg-5">
                        <h2 className="med text-primary text-uppercase mb-0 pb-0">
                            Contact Us
                        </h2>
                        <h3 className="f-third sml text-dark mb-0 pb-0">
                            Get an estimate from your Bergen County remodeling contractor today!
                        </h3>
                        <p className="pt-3">
                            <Link to="tel:+1-2019945354" className="btn btn-banner bg-quaternary my-2 mr-xl-3">Call us at
                                201-994-5354</Link>
                            <Link to="/contact-us" className="btn btn-banner my-2">Complete Our Request Form</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>) ;
    }
}
export default withRouter(FooterUpper);