import React from "react";
import {Link} from "react-router-dom";
import {FooterUpper} from "./index";

function Siding() {
    return (
        <div>
            <div id="banner">
                <div class="bg-picture-container">
                    <picture>
                        <source media="(max-width:568px)" type="image/webp" srcset="/fbm-data/images/layout/banner/banner-interior-m.webp"/>
                            <source type="image/webp" srcset="/fbm-data/images/layout/banner/banner-interior.webp"/>
                                <img src="/fbm-data/images/layout/banner/banner-interior.jpg" class="bg-picture-center" alt="remodeling company"/>
                    </picture>
                </div>
            </div>
            <div id="page">
                <main class="container-fluid" id="content">
                    <div class="row bg-white">
                        <div class="col-xl-8 col-lg-10 col-12 mx-auto p-xl-5 py-5">
                            <div class="p-0 p-lg-5">
                                <div id="cms-breadcrumb" class="minimal-breadcrumb d-lg-flex d-none">
                                    <div class="trail">
                                        <Link title="Home" to="/">Home</Link>
                                    </div>

                                    <div class="trail">
                                        <Link title="Siding" to="/siding">Siding</Link>
                                    </div>

                                </div>
                                <h1 class="">We Offer Siding For Your Bergen County Home</h1>
                                <p>
                                    We're here to help make your home remodeling dreams come true with a wide array of siding options for your River Edge area home!
                                </p>
                                <p>
                                    Updating the look of your River Edge area home is easy when you call the home remodeling professionals at Aspen Home Remodeling. We offer a vast array of color choices and design options to make your vision a reality. We offer expert guidance, American-made products, and top-quality workmanship to transform your River Edge home into the home of your dreams.
                                </p>
                                <p>
                                    New siding will not only elevate your curb appeal and give you a custom design look for your home, but siding can save you big money on energy costs. With our harsh New Jersey winters, new siding can help keep your home warmer, and its strength and durability are hard to beat. Say goodbye to repainting your home's exterior and give your River Edge property a fresh appeal with new siding from the <Link to="/">River Edge siding contractor</Link> your friends and neighbors know and trust, Aspen Home Remodeling.
                                </p>

                                <hr/>
                                    <div class="row align-items-center p-3">
                                        <div class="col-md-4 col-12">
                                            <div class="text-center p-3">
                                                <Link title="Vinyl Siding" to="/vinyl-siding">
                                                    <picture>
                                                        <source type="image/webp" srcset="/fbm-data/images/siding/vinyl-siding.webp"/>
                                                            <img alt="Vinyl siding" src="/fbm-data/images/siding/vinyl-siding.jpg" class="w-100"/>
                                                    </picture>
                                                </Link>
                                            </div>
                                        </div>
                                        <div class="col-md-8 col-12">
                                            <h2 class="f-second text-secondary"><Link to="/vinyl-siding">Vinyl Siding</Link></h2>
                                            <p>
                                                <Link to="/vinyl-siding">Vinyl siding</Link> is the cost-effective option to dramatically transform your home's exterior. Known for its unsurpassed durability, vinyl siding offers a wide array of styles, colors, and textures to make your home remodeling vision a reality.
                                            </p>
                                            <Link to="/vinyl-siding" class="btn btn-more">Read More</Link>
                                        </div>
                                    </div>
                                    <hr/>
                                        <div class="row align-items-center p-3">
                                            <div class="col-md-4 col-12">
                                                <div class="text-center p-3">
                                                    <Link title="James Hardie Siding" to="/james-hardie-siding">
                                                        <picture>
                                                            <source type="image/webp" srcset="/fbm-data/images/siding/james-hardie-siding.webp"/>
                                                                <img alt="James hardie siding" src="/fbm-data/images/siding/james-hardie-siding.jpg" class="w-100"/>
                                                        </picture>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div class="col-md-8 col-12">
                                                <h2 class="f-second text-secondary"><Link to="/james-hardie-siding">James Hardie Siding</Link></h2>
                                                <p>
                                                    James Hardie siding is the recognized leader in fiber cement siding products for your River Edge area home. Hardie siding is available in a wide range of color and design options and is the perfect balance of cost savings and lifetime value.
                                                </p>
                                                <Link to="/james-hardie-siding" class="btn btn-more">Read More</Link>
                                            </div>
                                        </div>
                                        <hr/>

                                            <h2 class="">Your Hometown Home Improvement Pros, Offering Quality American-Made Products </h2>
                                            <p>
                                                Supporting our local economy is at the forefront of everyone's minds, as well as the need to buy American-made products. From our top-quality roofing materials to our energy-saving replacement windows, all of the products we use for our home remodeling projects are made in the USA. Using supplies verified by the Vinyl Siding Institute and Energy Star®-Approved Products means that we're committed to providing top-quality products that are installed by a team of trained professionals.
                                            </p>
                                            <h2 class="">Budget-Friendly Siding and Remodeling Solutions</h2>
                                            <p>
                                                If you've been dreaming of updating your home in the Paramus, River Edge, or Oradell communities but aren't sure if it will fit your budget, the pros at Aspen Home Remodeling offer easy financing options to help ease your concerns. With our free in-person and virtual estimates and honest, transparent, and up-front pricing on every remodeling project, we're the hometown remodeler who can make your home remodeling dreams a comfortable reality!
                                            </p>
                                            <h2 class=""> Home Remodeling Options To Revitalize Your Property </h2>
                                            <p>
                                                Enhancing your curb appeal and increasing your property's value are the most obvious benefits of remodeling your River Edge home, but did you know that remodeling also lowers your heating costs, reduces outside noises, and makes maintenance and clean-ups a breeze?
                                            </p>
                                            <p>
                                                The home remodeling pros at Aspen Home Remodeling offer a variety of services to improve the look and function of your home in the communities around River Edge, Oradell, and Paramus, including:
                                            </p>
                                            <ul class="">
                                                <li class="">Roof repair &amp; roof replacement</li>
                                                <li class="">James Hardie &amp; vinyl siding</li>
                                                <li class="">Custom windows, replacement windows, &amp; vinyl windows</li>
                                                <li class="">Entry doors</li>
                                                <li class="">Gutters, shutters, &amp; trim</li>
                                                <li class="">And much more!</li>
                                            </ul>
                                            <p>
                                                When you're looking for quick response times, clean worksites, and skilled and courteous professionals, call the pros at Aspen Home Remodeling. We don't just want to be your home remodeler, we want the be the home remodeler you refer to your friends, your neighbors, and your relatives!
                                            </p>
                            </div>
                        </div>
                    </div>

                    <FooterUpper/>
                </main>
            </div>
        </div>
    );
}

export default Siding;