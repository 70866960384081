import React from "react";
import {Link} from "react-router-dom";
import {FooterUpper} from "./index";

function WindowsTypes() {
    return (
        <div>
            <div id="banner">
                <div class="bg-picture-container">
                    <picture>
                        <source media="(max-width:568px)" type="image/webp" srcset="/fbm-data/images/layout/banner/banner-interior-m.webp"/>
                            <source type="image/webp" srcset="/fbm-data/images/layout/banner/banner-interior.webp"/>
                                <img src="/fbm-data/images/layout/banner/banner-interior.jpg" class="bg-picture-center" alt="remodeling company"/>
                    </picture>
                </div>
            </div>
            <div id="page">
                <main class="container-fluid" id="content">
                    <div class="row bg-white">
                        <div class="col-xl-8 col-lg-10 col-12 mx-auto p-xl-5 py-5">
                            <div class="p-0 p-lg-5">
                                <div id="cms-breadcrumb" class="minimal-breadcrumb d-lg-flex d-none">
                                    <div class="trail">
                                        <Link title="Home" to="/">Home</Link>
                                    </div>

                                    <div class="trail">
                                        <Link title="Windows" to="/window-company">Windows</Link>
                                    </div>

                                    <div class="trail">
                                        <Link title="Window Types" to="/window-types">Window Types</Link>
                                    </div>

                                </div>
                                <h1 class="">Window Types To Make Your Bergen County Home Stand Out</h1>
                                <picture>
                                    <source type="image/webp" srcset="/fbm-data/images/windows/window-types.webp"/>
                                        <img alt="Window types" src="/fbm-data/images/windows/window-types.jpg" class="img-r-dynamic"/>
                                </picture>
                                <p>
                                    When it comes to the best selection of American-made window types for your Paramus area home, Aspen Home Remodeling is the name your friends and neighbors know and trust for <Link to="/">window replacement in Paramus</Link> and the surrounding communities.
                                </p>
                                <p>
                                    New windows don't just look great, they offer a number of benefits, including:
                                </p>
                                <ul class="">
                                    <li class="">Keeping your home warmer</li>
                                    <li class="">Lowering fuel bills</li>
                                    <li class="">Reducing noise</li>
                                    <li class="">Easy cleaning</li>
                                    <li class="">Enhancing property values</li>
                                </ul>
                                <p>
                                    Aspen Home Remodeling is proud to offer our friends and neighbors from Paramus to Oradell to River Edge and surrounding communities a variety of energy-efficient window types that will give your home a stunning new appeal.
                                </p>
                                <p>
                                    All of our products are made in the USA, are Energy-Star rated, and come with full manufacturer's warranties. We're committed to quality workmanship on every project, which is why we also offer a warranty on our labor.
                                </p>
                                <p>
                                    From custom windows for your new home to <Link to="/replacement-windows">replacement windows</Link> for your more established Paramus residence, our skilled professionals have window types to meet every need. Vinyl, easy-tilt, double-hung, sliders - our window specialists will guide you through the process and help you choose the window types that fulfill your vision - and enhance your views of the outside world.
                                </p>
                                <h2 class=""> The Home Remodeling Pros In Paramus </h2>
                                <p>
                                    Your friends and neighbors in Paramus and surrounding northeastern New Jersey communities know and trust the pros at Aspen Home Remodeling for all of their exterior home remodeling needs. We're your hometown home remodeling professional, with over 25 years in the home remodeling industry. Roofing, siding, windows, entry doors - home remodeling isn't just our passion, it's in our DNA!
                                </p>
                                <p>
                                    At Aspen Home Remodeling, it's all about the relationships we build with our customers. We know what your home means to you, which is why we'll always treat your home with the same care and respect we'd treat our own. Our customers rave about our quick turnaround times, our professional follow-up throughout the project, the cleanliness of our job sites, and our skilled and courteous staff.
                                </p>
                                <p>
                                    All of our products are made in the USA, so you can be assured of quality workmanship and full warranties on all of our energy-rated products. We offer easy financing options and free virtual estimates at Aspen Home Remodeling, and we're ready to help you make your vision a reality.
                                </p>
                                <p>
                                    We want to be your go-to remodeling professional in the Oradell, Paramus, and River Edge communities. More than that, we want to be the remodeling professional you recommend to your friends, your neighbors, and your relatives!
                                </p>
                                <h2 class=""> Make Your Home Improvement Vision A Reality </h2>
                                <p>
                                    Remodeling your home's exterior is a great way to improve the look of your home, and can elevate your curb appeal and enhance your property's value! Keeping your home warm, lowering your fuel bills, and reducing noise are just a few added benefits.
                                </p>
                                <p>
                                    Whether you're a new homeowner looking to enhance the efficiency of your starter home or an established homeowner who has been dreaming of updating the look of your Paramus area property, the home remodeling experts at Aspen Home Improvement are here to make your vision a reality by offering:
                                </p>
                                <ul class="">
                                    <li class="">25+ Years of Experience</li>
                                    <li class="">USA-Made Materials</li>
                                    <li class="">Energy Star®-Approved Products</li>
                                    <li class="">Limited Lifetime Warranties</li>
                                    <li class="">Easy Financing Options</li>
                                    <li class="">FREE Virtual Estimates</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <FooterUpper/>
                </main>
            </div>
        </div>
    );
}

export default WindowsTypes;