import React from "react";
import {Link} from "react-router-dom";
import {FooterUpper} from "./index";

function RoofTypes() {
    return (
        <div>
            <div id="banner">
                <div class="bg-picture-container">
                    <picture>
                        <source media="(max-width:568px)" type="image/webp" srcset="/fbm-data/images/layout/banner/banner-interior-m.webp"/>
                            <source type="image/webp" srcset="/fbm-data/images/layout/banner/banner-interior.webp"/>
                                <img src="/fbm-data/images/layout/banner/banner-interior.jpg" class="bg-picture-center" alt="remodeling company"/>
                    </picture>
                </div>
            </div>
            <div id="page">
                <main class="container-fluid" id="content">
                    <div class="row bg-white">
                        <div class="col-xl-8 col-lg-10 col-12 mx-auto p-xl-5 py-5">
                            <div class="p-0 p-lg-5">
                                <div id="cms-breadcrumb" class="minimal-breadcrumb d-lg-flex d-none">

                                    <div class="trail">
                                        <Link title="Home" to="/">Home</Link>
                                    </div>

                                    <div class="trail">
                                        <Link title="Roofing" to="/roofing">Roofing</Link>
                                    </div>

                                    <div class="trail">
                                        <Link title="Roof Types" to="/roof-types">Roof Types</Link>
                                    </div>

                                </div>
                                <h1 class="">We Offer Bergen County A Nearly Endless Variety Of Roof Types</h1>
                                <picture>
                                    <source type="image/webp" srcset="/fbm-data/images/roofing/roof-types.webp"/>
                                        <img alt="Roof types" src="/fbm-data/images/roofing/roof-types.jpg" class="img-r-dynamic"/>
                                </picture>
                                <p>
                                    At Aspen Home Remodeling, we're proud to offer a wide variety of roof types for your River Edge area home. All of our roofing products are American-made by industry leaders and installed by skilled local professionals you know and trust. We offer a huge selection of choices to enhance the appeal of your home and make your vision a reality.
                                </p>
                                <p>
                                    Our roofing professionals will guide you through the process to choose the roof types and colors that work with your roof's shape and slope while fully complementing the exterior of your River Edge Home. Whether you're considering simple 3-tab shingles, dimensional shingles, or a metal roofing system, our roofing professionals will give you the roof types to make your Bergen County home the home of your dreams.
                                </p>
                                <p>
                                    <strong>Aspen Home Remodeling offers a variety of roof types to make your River Edge home stand out with a designer appeal!</strong>
                                </p>
                                <h2 class=""> Benefits Of New Roofing For Your River Edge Residence </h2>
                                <p>
                                    A new roof can really highlight the look of your River Edge home and give it a designer style, and with today's variety of enhanced roof types, your new roof may provide you with a number of unexpected benefits, including:
                                </p>
                                <ul class="">
                                    <li class="">Enhanced curb appeal</li>
                                    <li class="">Increased property value</li>
                                    <li class="">Reduction of heat loss</li>
                                    <li class="">Lower energy bills</li>
                                </ul>
                                <p>
                                    Enhanced noise reduction of your roof is your home's crowning glory. Treat your home like the castle that it is and call in the <Link to="/">River Edge roofing contractor</Link> your friends and neighbors trust for all of their home remodeling needs - Aspen Home Remodeling.
                                </p>
                                <h2 class=""> Your Hometown Roofing Contractor, Serving River Edge, Paramus, Oradell, And The Surrounding Communities </h2>
                                <p>
                                    We're the local roofing contractor your friends and neighbors in the northeast New Jersey region know and trust for all of their home remodeling and roofing needs. Whether you're updating the look of your home's exterior with new siding, are looking for cost savings with new energy-efficient windows, or are ready for a <Link to="/roof-replacement">roof replacement</Link>, the home improvement pros at Aspen Home Remodeling are here to make your vision a reality.
                                </p>
                                <p>
                                    We work with top-rated roofing industry manufacturers to deliver high-quality, durable roofing materials that are made in the USA. All of our products come with full manufacturer warranties, so you can feel confident that you'll always have top-quality results on every project.
                                </p>
                                <p>
                                    We understand what your home means to you, and we'll always treat your home with the same care and respect that we'd treat our own. We proudly offer:
                                </p>
                                <ul class="">
                                    <li class="">25+ Years of Experience</li>
                                    <li class="">USA-Made Materials</li>
                                    <li class="">Energy Star®-Approved Products</li>
                                    <li class="">Limited Lifetime Warranties</li>
                                    <li class="">Easy Financing Options</li>
                                    <li class="">FREE Virtual Estimates </li>
                                </ul>
                                <p>
                                    Call the roofing and remodeling professionals at Aspen Home Remodeling today at 201-994-5354 or fill out our online form. You'll be amazed by the difference we can make for the look and efficiency of your River Edge home. At Aspen Home Remodeling, we don't just want to be your home remodeler, we want to be the home remodeler you tell your friends, neighbors, and relatives to call, too!
                                </p>
                                <h2 class="">Roof Repairs For All Roof Types </h2>
                                <p>
                                    Aspen Home Remodeling has got you covered when it comes to roof repairs for a variety of roof types. If you've got leaks, have sustained roof damage, or are in need of emergency repairs, the roofing pros at Aspen Home Remodeling offer quick response times, hands-on emergency services, and will have your repairs completed in no time!
                                </p>
                            </div>
                        </div>
                    </div>

                    <FooterUpper/>
                </main>
        </div>
        </div>
    );
}

export default RoofTypes;