import React from "react";
import {Link} from "react-router-dom";
import {FooterUpper} from "./index";

function SiteMap() {
    return (
        <div>
            <div id="banner">
                <div class="bg-picture-container">
                    <picture>
                        <source media="(max-width:568px)" type="image/webp"
                                srcset="/fbm-data/images/layout/banner/banner-interior-m.webp"/>
                        <source type="image/webp" srcset="/fbm-data/images/layout/banner/banner-interior.webp"/>
                        <img src="/fbm-data/images/layout/banner/banner-interior.jpg" class="bg-picture-center"
                             alt="remodeling company"/>
                    </picture>
                </div>
            </div>
            <div id="page">
                <main class="container-fluid" id="content">
                    <div class="row bg-white">
                        <div class="col-xl-8 col-lg-10 col-12 mx-auto p-xl-5 py-5">
                            <div class="p-0 p-lg-5">
                                <div id="cms-breadcrumb" class="minimal-breadcrumb d-lg-flex d-none">
                                    <div class="trail">
                                        <Link title="Home" to="/">Home</Link>
                                    </div>

                                    <div class="trail">
                                        <Link title="Sitemap" to="/sitemap">Sitemap</Link>
                                    </div>

                                </div>
                                <h1>Aspen Home Remodeling Sitemap</h1>
                                <div id="main-sitemap-container">
                                    <ul class="sitemap-list list-group">
                                        <li class="list-group-item"><Link to="/privacy">Privacy Policy</Link></li>
                                        <li class="list-group-item"><Link to="/sitemap">Sitemap</Link></li>
                                        <li class="list-group-item"><Link to="/terms">Terms of Use</Link></li>
                                        <li class="list-group-item"><Link to="/reviews">Reviews</Link></li>
                                        <li class="list-group-item"><Link to="/contact-us">Contact Us</Link></li>
                                        <li class="list-group-item"><Link to="/">Home</Link></li>
                                        <li class="list-group-item"><Link to="/faq">FAQ</Link></li>
                                        <li class="list-group-item"><Link to="/about-us">About Us</Link></li>
                                        <li class="list-group-item"><Link to="/financing">Financing</Link></li>
                                        <li class="list-group-item"><Link to="/window-company">Windows</Link></li>
                                        <li class="list-group-item"><Link to="/replacement-windows">Replacement
                                            Windows</Link></li>
                                        <li class="list-group-item"><Link to="/window-types">Window Types</Link></li>
                                        <li class="list-group-item"><Link to="/siding">Siding</Link></li>
                                        <li class="list-group-item"><Link to="/vinyl-siding">Vinyl Siding</Link></li>
                                        <li class="list-group-item"><Link to="/james-hardie-siding">James Hardie
                                            Siding</Link></li>
                                        <li class="list-group-item"><Link to="/roofing">Roofing</Link></li>
                                        <li class="list-group-item"><Link to="/roof-repairs">Roof Repairs</Link></li>
                                        <li class="list-group-item"><Link to="/roof-replacement">Roof Replacement</Link>
                                        </li>
                                        <li class="list-group-item"><Link to="/roof-types">Roof Types</Link></li>
                                        <li class="list-group-item"><Link to="/entry-doors">Entry Doors</Link></li>
                                        <li class="list-group-item"><Link to="/roofing-contractor-tips">Articles</Link></li>
                                        <li class="list-group-item"><Link
                                            to="/roofing-contractor-tips/hire-a-home-remodeling-professional">Hire A
                                            Home Remodeling Professional</Link></li>
                                        <li class="list-group-item"><Link
                                            to="/roofing-contractor-tips/five-signs-you-need-a-new-roof">Five Signs
                                            You Need A New Roof</Link></li>
                                        <li class="list-group-item"><Link
                                            to="/roofing-contractor-tips/best-roof-types-for-new-jersey-homes">Best
                                            Roof Types For New Jersey Homes</Link></li>
                                        <li class="list-group-item"><Link to="/near-me">Service Areas</Link></li>
                                        <li class="list-group-item"><Link to="/near-me/wyckoff-roofing-contractor">Wyckoff,
                                            NJ</Link></li>
                                        <li class="list-group-item"><Link to="/near-me/river-edge-roofing-contractor">River
                                            Edge, NJ</Link></li>
                                        <li class="list-group-item"><Link to="/near-me/ridgewood-roofing-contractor">Ridgewood,
                                            NJ</Link></li>
                                        <li class="list-group-item"><Link to="/near-me/roofing-contractor-ramsey">Ramsey,
                                            NJ</Link></li>
                                        <li class="list-group-item"><Link to="/near-me/paramus-roofing-contractor">Paramus,
                                            NJ</Link></li>
                                        <li class="list-group-item"><Link to="/near-me/oradell-roofing-contractor">Oradell,
                                            NJ</Link></li>
                                        <li class="list-group-item"><Link to="/near-me/roofing-contractor-mahwah">Mahwah,
                                            NJ</Link></li>
                                        <li class="list-group-item"><Link to="/near-me/roofing-contractor-hackensack">Hackensack,
                                            NJ</Link></li>
                                        <li class="list-group-item"><Link to="/near-me/roofing-contractor-glen-rock">Glen
                                            Rock, NJ</Link></li>
                                        <li class="list-group-item"><Link to="/near-me/roofing-contractor-emerson">Emerson,
                                            NJ</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterUpper/>
                </main>
            </div>
        </div>
    );
}

export default SiteMap;