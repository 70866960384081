import React from "react";
import {Link} from "react-router-dom";
import {FooterUpper} from "./index";

class Projects extends React.Component {

    constructor(props) {
        super(props);
        this.state = {data: []};
    }

    componentDidMount() {
        this.getProjects();
    }

    getProjects = async () => {
        const data = await fetch("/.netlify/functions/get-projects")
            .then((res) => res.json())
            .catch((err) => console.error(err));
        this.setState({data})
    }

    render() {
        return (
            <div>
                <div id="banner">
                    <div class="bg-picture-container">
                        <picture>
                            <source media="(max-width:568px)" type="image/webp"
                                    srcset="/fbm-data/images/layout/banner/banner-interior-m.webp"/>
                            <source type="image/webp" srcset="/fbm-data/images/layout/banner/banner-interior.webp"/>
                            <img src="/fbm-data/images/layout/banner/banner-interior.jpg" class="bg-picture-center"
                                 alt="remodeling company"/>
                        </picture>
                    </div>
                </div>
                <div id="page">
                    <main class="container-fluid" id="content">
                        <div class="row bg-white">
                            <div class="col-xl-8 col-lg-10 col-12 mx-auto p-xl-5 py-5">
                                <div class="p-0 p-lg-5">
                                    <div id="cms-breadcrumb" class="minimal-breadcrumb d-lg-flex d-none">
                                        <div class="trail">
                                            <Link title="Home" to="/">Home</Link>
                                        </div>

                                        <div class="trail">
                                            <Link title="Latest Projects" to="/projects">Latest Projects</Link>
                                        </div>

                                    </div>
                                    <h1 class="">View The Latest Roofing Contractor Projects By Aspen Home
                                        Remodeling</h1>

                                    <div paginate elements-per-page="10" columns="1" paginator-classes="my-2">
                                        <div class="row">
                                            {
                                                this.state.data.map(item =>
                                                    <div className="col-xl-12">
                                                    <article>
                                                        <img width="175" className="img-l-dynamic"
                                                             alt={item.title}
                                                             src={item.image}/>
                                                        <h2><Link
                                                            to={"/projects/"+item.slug}>{item.title}</Link>
                                                        </h2>
                                                        <p>{item.short} [&hellip;]</p>
                                                    </article>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <FooterUpper/>
                    </main>
                </div>
            </div>
        );
    }
}

export default Projects;
