import React from "react";
import {Link} from "react-router-dom";
import {FooterUpper} from "./index";

function RoofReplacement() {
    return (
        <div>
            <div id="banner">
                <div class="bg-picture-container">
                    <picture>
                        <source media="(max-width:568px)" type="image/webp" srcset="/fbm-data/images/layout/banner/banner-interior-m.webp"/>
                            <source type="image/webp" srcset="/fbm-data/images/layout/banner/banner-interior.webp"/>
                                <img src="/fbm-data/images/layout/banner/banner-interior.jpg" class="bg-picture-center" alt="remodeling company"/>
                    </picture>
                </div>
            </div>
            <div id="page">
                <main class="container-fluid" id="content">
                    <div class="row bg-white">
                        <div class="col-xl-8 col-lg-10 col-12 mx-auto p-xl-5 py-5">
                            <div class="p-0 p-lg-5">
                                <div id="cms-breadcrumb" class="minimal-breadcrumb d-lg-flex d-none">

                                    <div class="trail">
                                        <Link title="Home" to="/">Home</Link>
                                    </div>

                                    <div class="trail">
                                        <Link title="Roofing" to="/roofing">Roofing</Link>
                                    </div>

                                    <div class="trail">
                                        <Link title="Roof Replacement" to="/roof-replacement">Roof Replacement</Link>
                                    </div>

                                </div>
                                <h1 class="">Your Bergen County Roof Replacement Specialists</h1>
                                <picture>
                                    <source type="image/webp" srcset="/fbm-data/images/roofing/roof-replacement.webp"/>
                                        <img alt="Roof replacement" src="/fbm-data/images/roofing/roof-replacement.jpg" class="img-r-dynamic"/>
                                </picture>
                                <p>
                                    <strong>The roof replacement professionals at Aspen Home Remodeling are here to help you turn your Paramus home into the home of your dreams.</strong> We're your hometown roofing contractor, with over a quarter of a century of experience in the home remodeling industry. We understand what your home means to you, which is why we'll always treat your property with the same respect and care that we'd treat our own.
                                </p>
                                <p>
                                    At Aspen Home Remodeling, our skilled roof replacement specialists will guide you through the process and provide you with exceptional customer service. Our repeat customers often comment on our prompt response times, the cleanliness of our job sites, and our skilled and courteous staff.
                                </p>
                                <p>
                                    In addition, Aspen Home Remodeling offers:
                                </p>
                                <ul class="">
                                    <li class="">25+ Years of Experience</li>
                                    <li class="">USA-Made Materials</li>
                                    <li class="">Energy Star®-Approved Products</li>
                                    <li class="">Limited Lifetime Warranties</li>
                                    <li class="">Easy Financing Options</li>
                                    <li class="">FREE Virtual Estimates</li>
                                </ul>
                                <p>
                                    Our roof replacement products are American-made by industry leaders, and all products come with full manufacturer's warranties. We stand behind our work and offer a ten-year warranty on labor, too. Aspen Home Remodeling doesn't just want to be the <Link to="/">Paramus roofing contractor</Link> you call for your roof replacement, we want to be the roofing contractor you tell your friends, your neighbors, and your relatives to call, too!
                                </p>
                                <h2 class="">Roofing Styles To Give Your Home A High-End Appearance </h2>
                                <p>
                                    Whether you're in the market for basic asphalt shingles, durable metal roofing, or trendy dimensional shingles, our skilled professionals will guide you through our vast array of color and style choices to bring your vision to life. At Aspen Home Remodeling, we use top-quality materials made in the USA by industry leaders for all of our roof replacement projects.
                                </p>
                                <h2 class=""> Roof Repairs For Your Paramus Home </h2>
                                <p>
                                    If your roof is damaged, has issues with leaking, or if you need emergency <Link to="/roof-repairs">roof repairs</Link>, Aspen Home Remodeling offers fast response times, hands-on emergency services, and will always treat your home with the same care and concern we'd treat our own. We offer a wide variety of roofing products - all made in the USA by industry leaders - and can provide you with expert guidance to choose the color and style design that will best suit your needs.
                                </p>
                                <h2 class="">Budget-Friendly Pricing And Finance Options  </h2>
                                <p>
                                    A roof replacement is a big investment. Aspen Home Remodeling offers easy and convenient financing options to help make your home remodeling dreams a reality. We're proud to offer our friends and neighbors in Paramus budget-friendly transparent pricing and free virtual estimates. If you've been on the fence about remodeling the exterior of your home in the Paramus, Oradell, River Edge, and the surrounding communities, Aspen Home Remodeling is the roofing contractor your friends and neighbors know and trust for all of their roofing needs.
                                </p>
                                <h2 class=""> Transform The Look Of Your Paramus Area Home </h2>
                                <p>
                                    Our home remodeling services will transform the look of your home's exterior, enhancing your property values and elevating your curb appeal. Adding new siding, energy-efficient windows and entry doors, new roofing, and other fixtures to your home's exterior will keep your home warmer, reduce your fuel costs, and prevent outside noises from disturbing the tranquility of your home.
                                </p>
                                <h2>Latest Roof Replacement Projects</h2>
                                <div class="row mx-auto px-2 py-0">

                                    <div class="col-xl-4 col-md-6 col-12 mx-auto p-1">
                                        <div class="card h-100 project-cards">
                                            <picture>
                                                <img src="/fbm-data/images/projects/roofing-siding-new-entry-door-new-milford-nj-after.webp" alt="Roofing, Siding, & New Entry Door Project in New Milford, NJ" class="card-img-top"/>
                                            </picture>
                                            <div class="card-body">
                                                <div class="project-title">
                                                    <Link to="/projects/roofing-siding-new-entry-door-project-in-new-milford-nj">Roofing, Siding, & New Entry Door Project in New Milford, NJ</Link>
                                                </div>
                                                <p class="my-2">Aspen Home Remodeling recently completed a project of roof replacement, aluminum siding removal with new vinyl siding, and a front entry door replacement.



                                                    Roof Replacement
                                                    Vinyl Siding
                                                    Entry D [&hellip;]</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-md-6 col-12 mx-auto p-1">
                                        <div class="card h-100 project-cards">
                                            <picture>
                                                <img src="/fbm-data/images/projects/home-remodeling-project-oradell-nj-after.webp" alt="Before & After Home Remodeling Project in Oradell, NJ" class="card-img-top"/>
                                            </picture>
                                            <div class="card-body">
                                                <div class="project-title">
                                                    <Link to="/projects/before-after-home-remodeling-project-in-oradell-nj">Before & After Home Remodeling Project in Oradell, NJ</Link>
                                                </div>
                                                <p class="my-2">Removal of wood shakes, old wood windows, an old entry door, old wood garage doors, and gutters. Remodeled home with Insulated Vinyl Siding, new black vinyl windows, a new fiberglass entry door, new  [&hellip;]</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                    <FooterUpper/>
                </main>
        </div>
        </div>
    );
}

export default RoofReplacement;