import React from "react";
import {Link, withRouter} from "react-router-dom";
import {FooterUpper} from "./index";

class RoofingContractorEmerson extends React.Component {
    render() {
        return (<div>
            <div id="banner">
                <div className="bg-picture-container">
                    <picture>
                        <source media="(max-width:568px)" type="image/webp"
                                srcSet="/fbm-data/images/layout/banner/banner-interior-m.webp"/>
                        <source type="image/webp" srcSet="/fbm-data/images/layout/banner/banner-interior.webp"/>
                        <img src="/fbm-data/images/layout/banner/banner-interior.jpg"
                             className="bg-picture-center" alt="remodeling company"/>
                    </picture>
                </div>
            </div>
            <div id="page">
                <div className="container-fluid my-5">
                    <div className="row">
                        <div className="col-12 col-xl-8 mx-auto">
                            <div id="cms-breadcrumb" className="minimal-breadcrumb d-lg-flex d-none">
                                <div className="trail">
                                    <Link title="Home" to="/">Home</Link>
                                </div>

                                <div className="trail">
                                    <Link title="Service Areas" to="/near-me">Service Areas</Link>
                                </div>

                                <div className="trail">
                                    <Link title="Emerson, NJ" to="/near-me/roofing-contractor-emerson">Emerson,
                                        NJ</Link>
                                </div>

                            </div>
                            <div id="content">
                                <h1 className="">Emerson's Leader in Roofing Contractor Services</h1>
                                <picture>
                                    <source type="image/webp"
                                            srcSet="/fbm-data/images/cities/emerson-replacement-windows.webp"/>
                                    <img alt="Emerson replacement windows"
                                         src="/fbm-data/images/cities/emerson-replacement-windows.jpg"
                                         className="img-r-dynamic"/>
                                </picture>
                                <p>
                                    From roof repairs to complete roof replacement, Aspen Home Remodeling is the <Link
                                    to="/">roofing contractor in Emerson</Link> your friends and neighbors trust for all
                                    of their home remodeling needs! We're your hometown roofing contractor, with over a
                                    quarter of a century of experience in the home remodeling industry. Aspen Home
                                    Remodeling understands what your home means to you - we'll treat your property with
                                    the same respect and care that we'd treat our own.
                                </p>
                                <p>
                                    Your professional roofing contractor will guide you through the process from start
                                    to finish, from choosing design and color options to follow-up on your satisfaction
                                    and future remodeling projects. We're known for our prompt response times, the
                                    cleanliness of our job sites, and our skilled and courteous staff.
                                </p>
                                <h2 className=""> American-Made Products, Quality Workmanship By Emerson's Premier
                                    Roofing Contractor </h2>
                                <p>
                                    At Aspen Home Remodeling, we want you to feel proud to support a local business with
                                    a reputation for quality workmanship. All of our roofing products are American-made
                                    by recognized industry leaders and come with full manufacturer warranties. Whether
                                    you're in the market for basic asphalt shingles, durable metal roofing, or trendy
                                    dimensional shingles, we offer a boundless supply of color and style choices to
                                    bring your vision to life.
                                </p>
                                <h2 className="">Emergency And Scheduled Roof Repairs </h2>
                                <p>
                                    Whether you have minor leaks or need emergency roof repairs, Aspen Home Remodeling
                                    offers fast response times, hands-on emergency services, and will always treat your
                                    home with the same care and concern we'd treat our own. Our top-quality roofing
                                    materials are made in the USA by industry leaders and our skilled roofing experts
                                    will guide you through our wide array of color and style designs that will best suit
                                    your needs.
                                </p>
                                <h2 className="">Easy Financing Options And Budget-Friendly Pricing </h2>
                                <p>
                                    A roof replacement is a big investment, and unexpected repairs can take a big hit to
                                    your budget. Aspen Home Remodeling wants you to have options, which is why we offer
                                    easy and convenient financing to help make your home remodeling dreams a reality and
                                    to keep your emergency from breaking the bank. We're proud to offer our friends and
                                    neighbors in Emerson transparent and up-front pricing and free virtual estimates.
                                </p>
                                <p>
                                    If you've been on the fence about remodeling the exterior of your Emerson home,
                                    Aspen Home Remodeling makes the choice easy. Call us today to speak to one of our
                                    skilled and courteous home remodeling professionals and let us show you how to make
                                    your home improvement dreams a reality.
                                </p>
                                <h2 className=""> From Roofing To Replacement Windows - Transform The Look Of Your
                                    Emerson Area Home </h2>
                                <p>
                                    We offer a complete line of home remodeling services that will transform the look of
                                    your home's exterior. You'll be amazed by the difference home remodeling can make
                                    for your Emerson area property - enhancing your property values and elevating your
                                    home's curb appeal.
                                </p>
                                <p>
                                    From energy-efficient vinyl windows to noise-reducing <Link
                                    to="/james-hardie-siding">James Hardie siding</Link>, our home remodeling services
                                    can also improve the quality of your home's efficiency and comfort. Aspen Home
                                    remodeling is proud to offer:
                                </p>
                                <ul className="">
                                    <li className="">25+ Years of Experience</li>
                                    <li className="">USA-Made Materials</li>
                                    <li className="">Energy Star®-Approved Products</li>
                                    <li className="">Limited Lifetime Warranties</li>
                                    <li className="">Easy Financing Options</li>
                                    <li className="">FREE Virtual Estimates</li>
                                </ul>
                                <p>
                                    <strong>Aspen Home Remodeling wants to be your go-to remodeling and roofing
                                        contractor, but we also want to be the remodeling and roofing contractor you
                                        refer to your friends, neighbors, and family in Emerson and the surrounding
                                        communities.</strong> Call us today and let us make your home remodeling vision
                                    a reality.<strong> </strong>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-xl-8 mx-auto">
                            <div className="text-center">
                                <div className="my-3">
                                    <a rel="noreferrer" className="btn btn-more" target="_blank" href="https://www.emersonnj.org/">Emerson,
                                        New Jersey</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-6 mx-auto">
                                    <div className="embed-responsive embed-responsive-16by9">
                                        <iframe title="map" className="embed-responsive-item"
                                                src="//maps.google.com/?q=Emerson+New+Jersey&output=embed"></iframe>
                                    </div>
                                </div>
                            </div>
                            <div className="my-5">
                                <div className="testimonials-container">
                                    <div className="row">
                                        <div className="col-12"><h2>Reviews & Testimonials on Aspen Home Remodeling in
                                            Emerson, NJ</h2></div>
                                        <div className="w-100"></div>
                                        <div className="col-12"><p>
                                            There are currently no reviews or testimonials for <em>Emerson, NJ</em>.
                                            Check back soon!
                                        </p></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterUpper/>
                </div>
            </div>
        </div>);
    }
}

export default withRouter(RoofingContractorEmerson);
