import React from "react";
import {Link, withRouter} from "react-router-dom";

function Navigation(props) {
    console.log(props.location.pathname);
    console.log(props.match);
    console.log(props.history);
    if (props.location.pathname === '/landing' || props.location.pathname === '/thankyou') {
        const css = `
    @media only screen and (max-width: 600px) {
  #large-navigation-container-2 {
    position: absolute;
  }
  #page{
  margin-top: 175px !important;
  }
  #landing-img-wrapper{
  min-height: 349px !important;
  }
}
`
        return (
            <div>
                <style>{css}</style>
                <div className="d-xl-block p-0" id="large-navigation-container-2" style={{backgroundColor: '#0a2a6b'}}>
                    <div className="row bg-primary align-items-center mx-auto">
                        <div className="col-12 bigger-wrap mx-auto" style={{paddingTop: '20px', paddingBottom: '20px'}}>
                            <div className="row align-items-center mx-auto">
                                <div className="col text-left mx-auto px-2 py-1">
                                    {/*<a href="https://app.gethearth.com/financing/18871/26778/prequalify" target="_blank" rel="noreferrer" className="btn btn-financing">*/}
                                    {/*    Financing Available - Click for Details*/}
                                    {/*</a>*/}
                                </div>
                                <div className="f-second text-light mr-2 mb-0 pb-0 text-center"><h4>FALL SALE!<br/>
                                    25% Off All Roofing, Siding and Windows<br/>Request a FREE Consultation
                                    Today!<br/><Link to="tel:+1-2019945354" className="call-num">201-994-5354</Link>
                                </h4></div>
                                <div className="col text-right mx-auto px-2 py-1">
                                    {/*<div>*/}
                                    {/*<span className="text-light text-uppercase mr-2 mb-0 pb-0">*/}
                                    {/*	<i className="fab fa-cc-mastercard mr-1"></i>*/}
                                    {/*	<i className="fab fa-cc-visa"></i>*/}
                                    {/*</span>*/}
                                    {/*            <span className="f-third text-light mr-2 mb-0 pb-0">*/}
                                    {/*	&bull;*/}
                                    {/*</span>*/}
                                    {/*            <span className="f-second text-light mb-0 pb-0">*/}
                                    {/*	Virtual Estimates &amp; Financing Available*/}
                                    {/*</span>*/}
                                    {/*            <span className="f-third text-light mr-2 mb-0 pb-0">*/}
                                    {/*	&bull;*/}
                                    {/*</span>*/}
                                    {/*            <span className="f-second text-light mb-0 pb-0">*/}
                                    {/*	Free Estimates*/}
                                    {/*</span>*/}
                                    {/*        </div>*/}
                                    {/*        <div>*/}
                                    {/*<span className="f-second text-light mr-2 mb-0 pb-0">*/}
                                    {/*	Call Us Today at*/}
                                    {/*	<Link to="tel:+1-2019945354" className="call-num">201-994-5354</Link>*/}
                                    {/*</span>*/}
                                    {/*            <span className="f-second text-light mr-2 mb-0 pb-0">*/}
                                    {/*	&bull;*/}
                                    {/*</span>*/}
                                    {/*            <span className="f-second text-light mb-0 pb-0">*/}
                                    {/*            Request a <Link class="req-serv" to="/contact-us">Free Consultation</Link>*/}
                                    {/*</span>*/}
                                    {/*        </div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div>
                <div className="d-block d-xl-none container-fluid" id="small-navigation-container-1">
                    <div className="row bg-primary align-items-center">
                        <div className="col-12 text-right mx-auto pr-3 py-1">
				<span className="f-second text-light text-uppercase mb-0 pb-0">
					Call Us at
					<Link to="tel:+1-2019945354" className="call-num">201-994-5354</Link>
				</span>
                            <div>
							<span className="text-light text-uppercase mr-2 mb-0 pb-0">
								<i className="fab fa-cc-mastercard mr-1"></i>
								<i className="fab fa-cc-visa"></i>
							</span>
                                <span className="f-third text-light mr-2 mb-0 pb-0">
								&bull;
							</span>
                                <span className="f-second text-light mb-0 pb-0">
								Virtual Estimates &amp; Financing Available
							</span>
                                <span className="f-third text-light mr-2 mb-0 pb-0">
								&bull;
							</span>
                                <span className="f-second text-light mb-0 pb-0">
								Free Estimates
							</span>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="f-second text-light mr-2 mb-0 pb-0 text-center"><h4>Get 25% Off All Roofing, Siding
                        and
                        Windows!<br/> Get a FREE Consultation Today!</h4></div>
                    <div className="row align-items-center">
                        <div className="col text-left mx-auto pl-3 py-2">
                            <Link to="/">
                                <picture>
                                    <source type="image/webp" srcSet="/fbm-data/images/layout/logo-lt-m.webp"/>
                                    <img src="/fbm-data/images/layout/logo-lt-m.png" alt="Aspen Home Remodeling logo"
                                         width="350"/>
                                </picture>
                            </Link>
                        </div>
                        <div className="col-2 text-right pr-3 py-2">
                            <Link id="small-navigation-menu-opener-1">
                                <img alt="Hamburger Menu" src="/fbm-data/images/layout/menu_hamburger_white.svg"
                                     width="36"/>
                            </Link>
                        </div>
                    </div>
                    <div
                        className="pullout-menu-backdrop position-fixed d-none flex-direction-column justify-content-end">
                        <div>
                            <div className="menu-heading">
                                <span>Menu</span>
                            </div>
                            <div className="navigation-links">
                                <div>
                                    <Link to="/">Home</Link>
                                </div>
                                <div>
                                    <Link to="/about-us">
                                        About <span className="float-right"><i className="fas fa-angle-down"></i></span>
                                    </Link>
                                    <div className="tap-dropdown">
                                        <div>
                                            <Link to="/about-us">About</Link>
                                        </div>
                                        <div>
                                            <Link to="/faq">FAQ</Link>
                                        </div>
                                        <div>
                                            <Link to="/near-me">Service Area</Link>
                                        </div>
                                        <div>
                                            <Link to="/projects">Latest Projects</Link>
                                        </div>
                                        <div>
                                            <Link to="/before-and-after-gallery">Before & After Gallery</Link>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <Link to="/window-company">
                                        Windows <span className="float-right"><i
                                        className="fas fa-angle-down"></i></span>
                                    </Link>
                                    <div className="tap-dropdown">
                                        <div>
                                            <Link to="/window-company">Windows</Link>
                                        </div>
                                        <div>
                                            <Link to="/replacement-windows">Replacement Windows</Link>
                                        </div>
                                        <div>
                                            <Link to="/window-types">Window Types</Link>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <Link to="/siding">
                                        Siding <span className="float-right"><i
                                        className="fas fa-angle-down"></i></span>
                                    </Link>
                                    <div className="tap-dropdown">
                                        <div>
                                            <Link to="/siding">Siding</Link>
                                        </div>
                                        <div>
                                            <Link to="/vinyl-siding">Vinyl Siding</Link>
                                        </div>
                                        <div>
                                            <Link to="/james-hardie-siding">James Hardie Siding</Link>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <Link to="/roofing">
                                        Roofing <span className="float-right"><i
                                        className="fas fa-angle-down"></i></span>
                                    </Link>
                                    <div className="tap-dropdown">
                                        <div>
                                            <Link to="/roofing">Roofing</Link>
                                        </div>
                                        <div>
                                            <Link to="/roof-repairs">Roof Repairs</Link>
                                        </div>
                                        <div>
                                            <Link to="/roof-replacement">Roof Replacement</Link>
                                        </div>
                                        <div>
                                            <Link to="/roof-types">Roof Types</Link>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <Link to="/entry-doors">Entry Doors</Link>
                                </div>
                                <div>
                                    <Link to="/reviews">Reviews</Link>
                                </div>
                                <div>
                                    <Link to="/roofing-contractor-tips">Resources</Link>
                                </div>
                                <div>
                                    <a target="_blank" rel="noreferrer"
                                       href="https://app.gethearth.com/financing/18871/26778/prequalify?utm_campaign=18871&utm_content=general&utm_medium=contractor-website&utm_source=contractor&utm_term=26778">Financing</a>
                                </div>
                                <div>
                                    <Link to="/contact-us">Contact</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-none d-xl-block p-0" id="large-navigation-container-2">
                    <div className="row bg-primary align-items-center mx-auto">
                        <div className="col-12 bigger-wrap mx-auto">
                            <div className="row align-items-center mx-auto">
                                <div className="col text-left mx-auto px-2 py-1">
                                    <a href="https://app.gethearth.com/financing/18871/26778/prequalify" target="_blank"
                                       rel="noreferrer" className="btn btn-financing">
                                        Financing Available - Click for Details
                                    </a>
                                </div>
                                <div className="f-second text-light mr-2 mb-0 pb-0 text-center"><h4>Get 25% Off All
                                    Roofing,
                                    Siding and Windows!<br/> Get a FREE Consultation Today!</h4></div>
                                <div className="col text-right mx-auto px-2 py-1">
                                    <div>
							<span className="text-light text-uppercase mr-2 mb-0 pb-0">
								<i className="fab fa-cc-mastercard mr-1"></i>
								<i className="fab fa-cc-visa"></i>
							</span>
                                        <span className="f-third text-light mr-2 mb-0 pb-0">
								&bull;
							</span>
                                        <span className="f-second text-light mb-0 pb-0">
								Virtual Estimates &amp; Financing Available
							</span>
                                        <span className="f-third text-light mr-2 mb-0 pb-0">
								&bull;
							</span>
                                        <span className="f-second text-light mb-0 pb-0">
								Free Estimates
							</span>
                                    </div>
                                    <div>
							<span className="f-second text-light mr-2 mb-0 pb-0">
								Call Us Today at
								<Link to="tel:+1-2019945354" className="call-num">201-994-5354</Link>
							</span>
                                        <span className="f-second text-light mr-2 mb-0 pb-0">
								&bull;
							</span>
                                        <span className="f-second text-light mb-0 pb-0">
                                        Request a <Link class="req-serv" to="/contact-us">Free Consultation</Link>
							</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row bigger-wrap mx-auto align-items-center">
                        <div className="col text-left mx-auto pl-2 py-1">
                            <Link to="/">
                                <picture>
                                    <source type="image/webp" srcSet="/fbm-data/images/layout/logo-lt.webp"/>
                                    <img src="/fbm-data/images/layout/logo-lt.png" alt="Aspen Home Remodeling logo"
                                         width="350"/>
                                </picture>
                            </Link>
                        </div>
                        <div className="col-9 text-right mx-auto pr-2 py-0">
                            <div className="container-fluid navigation-links mx-auto">
                                <div className="row">
                                    <div className="col">
                                        <Link to="/">Home</Link>
                                    </div>
                                    <div className="col">
                                        <Link to="/about-us">About</Link>
                                        <div className="dropdown">
                                            <div>
                                                <Link to="/faq">FAQ</Link>
                                            </div>
                                            <div>
                                                <Link to="/near-me">Service Area</Link>
                                            </div>
                                            <div>
                                                <Link to="/projects">Latest Projects</Link>
                                            </div>
                                            <div>
                                                <Link to="/before-and-after-gallery">Before & After Gallery</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <Link to="/window-company">Windows</Link>
                                        <div className="dropdown">
                                            <div>
                                                <Link to="/replacement-windows">Replacement Windows</Link>
                                            </div>
                                            <div>
                                                <Link to="/window-types">Window Types</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <Link to="/siding">Siding</Link>
                                        <div className="dropdown">
                                            <div>
                                                <Link to="/vinyl-siding">Vinyl Siding</Link>
                                            </div>
                                            <div>
                                                <Link to="/james-hardie-siding">James Hardie Siding</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <Link to="/roofing">Roofing</Link>
                                        <div className="dropdown">
                                            <div>
                                                <Link to="/roof-repairs">Roof Repairs</Link>
                                            </div>
                                            <div>
                                                <Link to="/roof-replacement">Roof Replacement</Link>
                                            </div>
                                            <div>
                                                <Link to="/roof-types">Roof Types</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <Link to="/entry-doors">Entry&nbsp;Doors</Link>
                                    </div>
                                    <div className="col">
                                        <Link to="/reviews">Reviews</Link>
                                    </div>
                                    <div className="col">
                                        <Link to="/roofing-contractor-tips">Resources</Link>
                                    </div>
                                    <div className="col">
                                        <a target="_blank" rel="noreferrer"
                                           href="https://app.gethearth.com/financing/18871/26778/prequalify?utm_campaign=18871&utm_content=general&utm_medium=contractor-website&utm_source=contractor&utm_term=26778">Financing</a>
                                    </div>
                                    <div className="col">
                                        <Link to="/contact-us">Contact</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Navigation);

