import React from "react";
import {Link} from "react-router-dom";
import {FooterUpper} from "./index";
function Faq() {
    return (
        <div>
            <div id="banner">
                <div class="bg-picture-container">
                    <picture>
                        <source media="(max-width:568px)" type="image/webp" srcset="/fbm-data/images/layout/banner/banner-interior-m.webp"/>
                            <source type="image/webp" srcset="/fbm-data/images/layout/banner/banner-interior.webp"/>
                                <img src="/fbm-data/images/layout/banner/banner-interior.jpg" class="bg-picture-center" alt="remodeling company"/>
                    </picture>
                </div>
            </div>
            <div id="page">
                <main class="container-fluid" id="content">
                    <div class="row bg-white">
                        <div class="col-xl-8 col-lg-10 col-12 mx-auto p-xl-5 py-5">
                            <div class="p-0 p-lg-5">
                                <div id="cms-breadcrumb" class="minimal-breadcrumb d-lg-flex d-none">
                                    <div class="trail">
                                        <Link title="Home" to="/">Home</Link>
                                    </div>

                                    <div class="trail">
                                        <Link title="FAQ" to="/faq">FAQ</Link>
                                    </div>

                                </div>
                                <h1 class="">Frequently Asked Roofing Contractor Questions - Answered By Aspen Home Remodeling</h1>
                                <p>
                                    At Aspen Home Remodeling, remodeling is in our DNA and we're skilled professionals when it comes to roofing, siding, exterior doors, windows, and much more! If you've got questions, we've got the know-how to provide you with the answers you're looking for. Here are a few of our more frequently asked questions:
                                </p>
                                <div itemscope itemtype="https://schema.org/FAQPage">
                                    <hr/>
                                        <div itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                                            <h2 itemprop="name"><i class="fas fa-question-circle text-tertiary"></i> What Exactly Is James Hardie Siding?</h2>
                                            <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                                <div itemprop="text">
                                                    <p>
                                                        James Hardie siding is the recognized leader in fiber cement siding products for your Paramus area home. Known for its superior durability, its wide range of design and color options, and lifetime value, James Hardie siding also offers a number of additional benefits, including:
                                                    </p>
                                                    <ul class="">
                                                        <li class="">Cost savings</li>
                                                        <li class="">Impervious to insects and rot</li>
                                                        <li class="">Potential energy and insurance savings</li>
                                                        <li class="">Warranty on all products</li>
                                                        <li class="">Made in the USA</li>
                                                    </ul>
                                                    <p>
                                                        Your siding professional can discuss whether James Hardie siding is right for your home exterior remodeling project and show you color and design options to turn your Paramus home into your dream home!
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <hr/>
                                            <div itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                                                <h2 itemprop="name"><i class="fas fa-question-circle text-tertiary"></i> Are Different Window Types Good For Different Homes?</h2>
                                                <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                                    <div itemprop="text">
                                                        <p>
                                                            New windows can transform the look of your home, and the pros at Aspen Home Remodeling are here to guide you through the process of choosing the right windows for your Paramus area residence or vacation home. We offer a wide range of energy-efficient custom window options - including vinyl windows, skylights, and double-hung windows - that will not only elevate your home's curb appeal but can provide you with lower heating bills and reduce outside noises. We provide free estimates and can give you a personalized window replacement plan with budget-friendly finance options to meet your needs.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr/>
                                                <div itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                                                    <h2 itemprop="name"><i class="fas fa-question-circle text-tertiary"></i> How Long Does It Take My Roof To Be Replaced?</h2>
                                                    <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                                        <div itemprop="text">
                                                            <p>
                                                                As the top-rated <Link to="/">roofing contractor in Paramus</Link>, we work hard to provide quick response times to all of our requests for estimates. Once we've discussed product options and agreed to move forward with the project, we'll provide you with a personalized timeline and follow-up to ensure that you're satisfied with our progress. Because we are a hometown relationship-based business, our clients often work with us to accommodate urgent and emergency requests.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr/>
                                                    <div itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                                                        <h2 itemprop="name"><i class="fas fa-question-circle text-tertiary"></i> How Do I Wash My Vinyl Siding?</h2>
                                                        <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                                            <div itemprop="text">
                                                                <p>
                                                                    Vinyl siding is a virtually maintenance-free option for giving your home a fresh appeal. Vinyl siding is impervious to damage from insects or other invasive organisms and doesn't require repainting or routine replacement, which makes it a great choice to withstand our harsh New Jersey climate. Your siding installation specialist will review specific instructions for the care of your home's vinyl siding and will provide you with a personalized plan to meet your individual needs.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <FooterUpper/>
                </main>
            </div>
        </div>
    );
}
export default Faq;