import React from "react";
import {ContactForm} from "./index";
import {Link} from "react-router-dom";

function Landing() {
    return (
        <div>
            <div id="page" style={{marginTop:'125px'}}>
                <main class="container-fluid" id="content" style={{paddingRight:'0',paddingLeft:'0'}}>
                    <div className="row bg-light mx-auto py-3" style={{backgroundColor: '#0a2a6b'}} id='landing-wrapper'>

                        <div className="order-2 order-lg-1 col-xl-8 col-lg-8 col-12 mx-auto p-0" id="bg-windows">
                            <div className="bg-picture-container p-0" style={{minHeight:'668px'}} id="landing-img-wrapper">
                                <picture>
                                    <source media="(max-width:568px)" type="image/webp"
                                            srcSet="/fbm-data/images/aspenlm.webp"/>
                                    <source type="image/webp" srcSet="/fbm-data/images/aspenl.webp"/>
                                    <img src="/fbm-data/images/aspenl.png" className="bg-picture"
                                         alt="replacement windows"/>
                                </picture>
                            </div>
                        </div>
                        <div className="order-1 order-lg-2 col-xl-4 col-lg-4 col-12 mx-auto p-5" id="contact-form" style={{boxShadow:'none'}}>
                            <h2 className="text-center text-light ">Request a FREE<br/> Consultation Today!</h2>
                            <div id="wufoo-qis2wzj1k0ued3">
                                <ContactForm/>
                            </div>
                        </div>
                    </div>
                    <div className="row bg-primary p-1" style={{marginLeft:'0',marginRight:'0'}}>
                        <div className="col-lg-4 col-12 mx-auto p-1">
                            <div className="service-fadein-hover">
                                <picture>
                                    <source media="(max-width:568px)" type="image/webp"
                                            srcSet="/fbm-data/images/layout/services/service-windows-m.webp"/>
                                    <source type="image/webp" srcSet="/fbm-data/images/layout/services/service-windows.webp"/>
                                    <img src="/fbm-data/images/layout/services/service-windows.jpg"
                                         alt="replacement windows"/>
                                </picture>
                                <div className="service-content">
                                    <div className="service-title">
                                        Replacement Windows
                                    </div>
                                    <div className="service-links">
                                        <Link to="/replacement-windows" className="btn btn-service">
                                            <i className="fas fa-plus"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 mx-auto p-1">
                            <div className="service-fadein-hover">
                                <picture>
                                    <source media="(max-width:568px)" type="image/webp"
                                            srcSet="/fbm-data/images/layout/services/service-roofing-m.webp"/>
                                    <source type="image/webp" srcSet="/fbm-data/images/layout/services/service-roofing.webp"/>
                                    <img src="/fbm-data/images/layout/services/service-roofing.jpg" alt="roofing"/>
                                </picture>
                                <div className="service-content">
                                    <div className="service-title">
                                        Roofing
                                    </div>
                                    <div className="service-links">
                                        <Link to="/roofing" className="btn btn-service">
                                            <i className="fas fa-plus"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 mx-auto p-1">
                            <div className="service-fadein-hover">
                                <picture>
                                    <source media="(max-width:568px)" type="image/webp"
                                            srcSet="/fbm-data/images/layout/services/service-siding-m.webp"/>
                                    <source type="image/webp" srcSet="/fbm-data/images/layout/services/service-siding.webp"/>
                                    <img src="/fbm-data/images/layout/services/service-siding.jpg" alt="siding"/>
                                </picture>
                                <div className="service-content">
                                    <div className="service-title">
                                        Siding
                                    </div>
                                    <div className="service-links">
                                        <Link to="/siding" className="btn btn-service">
                                            <i className="fas fa-plus"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}

export default Landing;