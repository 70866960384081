import React from "react";
import {withRouter} from "react-router-dom";
import {FooterUpper} from "./index";

class RiverEdgeRoofingContractor extends React.Component{
    render() {
        return (<div>
            <div id="banner">
                <div className="bg-picture-container">
                    <picture>
                        <source media="(max-width:568px)" type="image/webp"
                                srcSet="/fbm-data/images/layout/banner/banner-interior-m.webp"/>
                            <source type="image/webp" srcSet="/fbm-data/images/layout/banner/banner-interior.webp"/>
                                <img src="/fbm-data/images/layout/banner/banner-interior.jpg"
                                     className="bg-picture-center" alt="remodeling company"/>
                    </picture>
                </div>
            </div>
            <div id="page">
                <div className="container-fluid my-5">
                    <div className="row">
                        <div className="col-12 col-xl-8 mx-auto">
                            <div id="cms-breadcrumb" className="minimal-breadcrumb d-lg-flex d-none">
                                <div className="trail">
                                    <a title="Home" href="/">Home</a>
                                </div>

                                <div className="trail">
                                    <a title="Service Areas" href="/near-me">Service Areas</a>
                                </div>

                                <div className="trail">
                                    <a title="River Edge, NJ" href="/near-me/river-edge-roofing-contractor">River Edge,
                                        NJ</a>
                                </div>

                            </div>
                            <div id="content">
                                <h1 className="">Your Local Roofing Contractor Company in River Edge, NJ</h1>
                                <picture>
                                    <source type="image/webp"
                                            srcSet="/fbm-data/images/cities/river-edge-replacement-windows.webp"/>
                                        <img alt="River edge replacement windows"
                                             src="/fbm-data/images/cities/river-edge-replacement-windows.jpg"
                                             className="img-r-dynamic"/>
                                </picture>
                                <p>
                                    <strong>When you're looking for a local roofing contractor for your River Edge area
                                        home, call the professionals your friends and neighbors in Bergen County trust
                                        for all of their roofing needs - Aspen Home Remodeling.</strong>
                                </p>
                                <p>
                                    We're the top-rated <a href="/">roofing contractor in River Edge</a>, and we serve
                                    an ever-growing number of satisfied customers in the communities around Paramus and
                                    Oradell with quality workmanship and professional results. We partner with some of
                                    the top-rated roofing manufacturers in the industry to bring you stylish, durable,
                                    and dependable roofing materials, proudly made in the USA by industry leaders who
                                    stand behind their products.
                                </p>
                                <h2 className="">Variety Of Roof Types</h2>
                                <p>
                                    The roofing materials we use at Aspen Home Remodeling are made in the USA by
                                    industry leaders and come with manufacturer's warranties. Whether you're looking to
                                    balance style and budget with an asphalt, architectural, or dimensional shingle
                                    roof, or want the durability of metal roofing, Aspen Home Remodeling offers a
                                    variety of roofing types to fit your needs.
                                </p>
                                <h2 className="">Trust Your Roof Repairs To River Edge's Reliable Roofing
                                    Contractor</h2>
                                <p>
                                    When you need <a href="/roof-repairs">roof repairs</a>, you can trust Aspen Home
                                    Remodeling to keep you covered. Whether you need emergency repairs or just have a
                                    minor roof leak, we offer quick response times, hands-on emergency services, and
                                    professional results.
                                </p>
                                <h2 className="">The Right Roofing Contractor For Roof Replacement</h2>
                                <p>
                                    New roofing can improve the curb appeal of your River Edge home while lowering your
                                    fuel costs and increasing your property's value. If you're ready to enjoy a
                                    beautiful new roof on your River Edge home, the roofing professionals at Aspen Home
                                    Remodeling offer a variety of color, design, and style choices to give you the roof
                                    of your dreams. With over 25 years of experience in the home remodeling industry,
                                    the roofing pros at Aspen Home Remodeling don't just want to be your roofing
                                    contractor, we want to be the roofing contractor you tell your friends, neighbors,
                                    and relatives to call.
                                </p>
                                <h2 className="">Replacement Windows Enhance Your River Edge Home </h2>
                                <p>
                                    We offer replacement windows for your River Edge area home, which are designed to
                                    retrofit a wide array of structures, providing you with an updated appeal and a
                                    revitalized appearance for your residence. Our stylish and well-built options are
                                    customizable, energy-efficient, and durable and will provide your River Edge home
                                    with lasting beauty for years to come.
                                </p>
                                <h2 className=""> Home Remodeling Services Your River Edge Neighbors Trust </h2>
                                <p>
                                    When it comes to your home, we know what it means to you. That's why Aspen Home
                                    Remodeling will always treat your home with the same care and concern we'd treat our
                                    own. Our customers rave about our relationship-based service, the cleanliness of our
                                    job sites, and our courteous and reliable staff.
                                </p>
                                <p>
                                    Aspen Home Remodeling proudly offers:
                                </p>
                                <ul className="">
                                    <li className="">25+ Years of Experience</li>
                                    <li className="">USA-Made Materials</li>
                                    <li className="">Energy Star®-Approved Products</li>
                                    <li className="">Limited Lifetime Warranties</li>
                                    <li className="">Easy Financing Options</li>
                                    <li className="">FREE Virtual Estimates</li>
                                </ul>
                                <p>
                                    We offer a complete line of home remodeling services to enhance the appeal and
                                    efficiency of your River Edge home, including:
                                </p>
                                <ul className="">
                                    <li className="">Roofing</li>
                                    <li className="">Siding</li>
                                    <li className="">Windows</li>
                                    <li className="">Entry doors</li>
                                    <li className="">Gutters &amp; trim</li>
                                    <li className="">Shutters</li>
                                </ul>

                                <p>
                                    When you're ready to make your home improvement vision a reality, call Aspen Home
                                    Remodeling at 201-994-5354 or fill out our online form and let us show give you the
                                    home of your dreams!
                                </p>
                                <h2 className="">Up-Front Pricing And Easy Financing </h2>
                                <p>
                                    Aspen Home Remodeling offers affordable options to meet any budget, transparent and
                                    up-front pricing, and easy financing to make your home remodeling dreams a
                                    comfortable reality!
                                </p>
                                <h2>Latest Projects in River Edge, NJ</h2>
                                <div className="row mx-auto px-2 py-0">

                                    <div className="col-xl-4 col-md-6 col-12 mx-auto p-1">
                                        <div className="card h-100 project-cards">
                                            <picture>
                                                <img
                                                    src="/fbm-data/images/projects/roofing-siding-new-entry-door-new-milford-nj-after.webp"
                                                    alt="Roofing, Siding, & New Entry Door Project in New Milford, NJ"
                                                    className="card-img-top"/>
                                            </picture>
                                            <div className="card-body">
                                                <div className="project-title">
                                                    <a href="/projects/roofing-siding-new-entry-door-project-in-new-milford-nj">Roofing,
                                                        Siding, & New Entry Door Project in New Milford, NJ</a>
                                                </div>
                                                <p className="my-2">Aspen Home Remodeling recently completed a project
                                                    of roof replacement, aluminum siding removal with new vinyl siding,
                                                    and a front entry door replacement.


                                                    Roof Replacement
                                                    Vinyl Siding
                                                    Entry D [&hellip;]</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-xl-8 mx-auto">
                            <div className="text-center">
                                <div className="my-3">
                                    <a rel="noreferrer" className="btn btn-more" target="_blank" href="https://www.riveredgenj.org/">River
                                        Edge, New Jersey</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-6 mx-auto">
                                    <div className="embed-responsive embed-responsive-16by9">
                                        <iframe title="map" className="embed-responsive-item"
                                                src="//maps.google.com/?q=River+Edge+New+Jersey&output=embed"></iframe>
                                    </div>
                                </div>
                            </div>
                            <div className="my-5">
                                <div className="testimonials-container">
                                    <div className="row">
                                        <div className="col-12"><h2>Reviews & Testimonials on Aspen Home Remodeling in
                                            River Edge, NJ</h2></div>
                                        <div className="w-100"></div>
                                        <div className="col-12"><p>
                                            There are currently no reviews or testimonials for <em>River Edge, NJ</em>.
                                            Check back soon!
                                        </p></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterUpper/>
                </div>
            </div>
        </div>);
    }
}

export default withRouter(RiverEdgeRoofingContractor);
