import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch,useLocation,withRouter } from "react-router-dom";
import {
  Navigation,
  Footer,
  Home,
  Contact,
  About,
  Faq,
  NearMe,
  Projects,
  BeforAndAfterGallery,
  WindowCompany,
  ReplacementWindows,
  WindowsTypes,
  Siding,
  VinylSliding,
  JamesHardieSiding,
  Roofing,
  RoofRepairs,
  RoofReplacement,
  RoofTypes,
  EntryDoors,
  Reviews,
  RoofingContractorTips,
  SiteMap,
  RiverEdgeRoofingContractor,
  OradellRoofingContractor,
  ParamusRoofingContractor,
  RidgewoodRoofingContractor,
  WyckoffRoofingContractor,
  RoofingContractorGlenRock,
  RoofingContractorEmerson,
  RoofingContractorHackensack,
  RoofingContractorMahwah,
  RoofingContractorRamsey,
  ProjectDetail, ArticleDetail, Landing, ThankYou
} from "./components";
function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children
}
const ScrollToTop = withRouter(_ScrollToTop)
function App() {
  return (
      <div className="App">
        <Router onUpdate={() => window.scrollTo(0, 0)}>
          <ScrollToTop>
          <Navigation />
          <Switch>
            <Route path="/" exact component={() => <Home />} />
            <Route path="/contact-us" exact component={() => <Contact />} />
            <Route path="/about-us" exact component={() => <About />} />
            <Route path="/faq" exact component={() => <Faq />} />
            <Route path="/near-me" exact component={() => <NearMe />} />
            <Route path="/projects" exact component={() => <Projects />} />
            <Route path="/projects/:project" exact component={() => <ProjectDetail />} />
            <Route path="/roofing-contractor-tips/:article" exact component={() => <ArticleDetail />} />
            <Route path="/before-and-after-gallery" exact component={() => <BeforAndAfterGallery />} />
            <Route path="/window-company" exact component={() => <WindowCompany />} />
            <Route path="/replacement-windows" exact component={() => <ReplacementWindows />} />
            <Route path="/window-types" exact component={() => <WindowsTypes />} />
            <Route path="/siding" exact component={() => <Siding />} />
            <Route path="/vinyl-siding" exact component={() => <VinylSliding />} />
            <Route path="/james-hardie-siding" exact component={() => <JamesHardieSiding />} />
            <Route path="/roofing" exact component={() => <Roofing />} />
            <Route path="/roof-repairs" exact component={() => <RoofRepairs />} />
            <Route path="/roof-replacement" exact component={() => <RoofReplacement />} />
            <Route path="/roof-types" exact component={() => <RoofTypes />} />
            <Route path="/entry-doors" exact component={() => <EntryDoors />} />
            <Route path="/reviews" exact component={() => <Reviews />} />
            <Route path="/roofing-contractor-tips" exact component={() => <RoofingContractorTips />} />
            <Route path="/sitemap" exact component={() => <SiteMap />} />
            <Route path="/landing" exact component={() => <Landing />} />
            <Route path="/thankyou" exact component={() => <ThankYou />} />
            <Route path="/near-me/river-edge-roofing-contractor" exact component={() => <RiverEdgeRoofingContractor />} />
            <Route path="/near-me/oradell-roofing-contractor" exact component={() => <OradellRoofingContractor />} />
            <Route path="/near-me/paramus-roofing-contractor" exact component={() => <ParamusRoofingContractor />} />
            <Route path="/near-me/ridgewood-roofing-contractor" exact component={() => <RidgewoodRoofingContractor />} />
            <Route path="/near-me/wyckoff-roofing-contractor" exact component={() => <WyckoffRoofingContractor />} />
            <Route path="/near-me/roofing-contractor-glen-rock" exact component={() => <RoofingContractorGlenRock />} />
            <Route path="/near-me/roofing-contractor-emerson" exact component={() => <RoofingContractorEmerson />} />
            <Route path="/near-me/roofing-contractor-hackensack" exact component={() => <RoofingContractorHackensack />} />
            <Route path="/near-me/roofing-contractor-mahwah" exact component={() => <RoofingContractorMahwah />} />
            <Route path="/near-me/roofing-contractor-ramsey" exact component={() => <RoofingContractorRamsey />} />
          </Switch>
          <Footer />
          </ScrollToTop>
        </Router>
      </div>
  );
}

export default App;