import React from "react";
import {Link} from "react-router-dom";
import {FooterUpper} from "./index";

function ReplacementWindows() {
    return (
        <div>
            <div id="banner">
                <div class="bg-picture-container">
                    <picture>
                        <source media="(max-width:568px)" type="image/webp"
                                srcset="/fbm-data/images/layout/banner/banner-interior-m.webp"/>
                        <source type="image/webp" srcset="/fbm-data/images/layout/banner/banner-interior.webp"/>
                        <img src="/fbm-data/images/layout/banner/banner-interior.jpg" class="bg-picture-center"
                             alt="remodeling company"/>
                    </picture>
                </div>
            </div>
            <div id="page">
                <main class="container-fluid" id="content">
                    <div class="row bg-white">
                        <div class="col-xl-8 col-lg-10 col-12 mx-auto p-xl-5 py-5">
                            <div class="p-0 p-lg-5">
                                <div id="cms-breadcrumb" class="minimal-breadcrumb d-lg-flex d-none">
                                    <div class="trail">
                                        <Link title="Home" to="/">Home</Link>
                                    </div>

                                    <div class="trail">
                                        <Link title="Windows" to="/window-company">Windows</Link>
                                    </div>

                                    <div class="trail">
                                        <Link title="Replacement Windows" to="/replacement-windows">Replacement
                                            Windows</Link>
                                    </div>

                                </div>
                                <h1 class="">Trust the Bergen County Area's Premier Choice In Replacement Windows</h1>
                                <picture>
                                    <source type="image/webp"
                                            srcset="/fbm-data/images/windows/replacement-windows.webp"/>
                                    <img alt="Replacement windows"
                                         src="/fbm-data/images/windows/replacement-windows.jpg" class="img-r-dynamic"/>
                                </picture>
                                <p>
                                    If you're looking for replacement windows for your Oradell area home, Aspen Home
                                    Remodeling is the replacement window specialist your friends and neighbors trust for
                                    all of their home remodeling needs!
                                </p>
                                <p>
                                    Whether you're a first-time homeowner looking to update your windows on a budget or
                                    a long-time homeowner who has had enough of your drafty old windows, our options
                                    for <Link to="/">window replacement for Oradell</Link> homes <Link to="/"></Link> are
                                    designed to retrofit a wide array of established homes in a variety of size, color,
                                    and custom choices.
                                </p>
                                <p>
                                    All of our products are made in the USA and carry factory warranties, and our labor
                                    is covered by warranty too, to give you the peace of mind you deserve. We offer
                                    replacement windows in a wide array of styles and <Link to="/window-types">window
                                    types</Link> that will enhance the beauty and appeal of your Oradell home.
                                </p>
                                <p>
                                    Replacement windows will not only enhance your Oradell views, but they'll also keep
                                    out the effects of the harsh New Jersey winters - improving your home's warmth and
                                    lowering your fuel costs. Our replacement windows are well-built, energy-efficient,
                                    and durable. Outdoor noise reduction is also an added bonus!
                                </p>
                                <p>
                                    <strong>The home remodeling specialist your friends and neighbors in Oradell call
                                        for replacement windows is Aspen Home Remodeling.</strong>
                                </p>
                                <h2 class=""> Variety Of Window Types To Enhance Your Oradell Home </h2>
                                <p>
                                    Your home has its own unique personality, and our window specialists will evaluate
                                    your project and help you choose the right windows to meet your specific needs. Our
                                    custom vinyl windows will elevate your home's aesthetic while providing you with
                                    advanced features like easy-tilt cleaning and noise reduction. Whether you're
                                    looking for skylights, double-hung windows, or sliders, we'll give you the right
                                    windows to enhance the appeal of your Oradell home.
                                </p>
                                <h2 class=""> Oradell's Home Remodeling Professionals </h2>
                                <p>
                                    At Aspen Home Remodeling, we understand what your home means to you. When it comes
                                    to providing top-quality remodeling services for your Oradell home, we'll always
                                    treat your property with the same care and respect that we'd treat our own.
                                </p>
                                <p>
                                    We offer a full line of exterior remodeling services to enhance the appeal of your
                                    Oradell area home, including:
                                </p>
                                <ul class="">
                                    <li class="">Roofing</li>
                                    <li class="">Siding</li>
                                    <li class="">Windows</li>
                                    <li class="">Entry doors</li>
                                    <li class="">Gutters &amp; trim</li>
                                    <li class="">Shutters</li>
                                </ul>
                                <p>
                                    Home exterior remodeling isn't just our job, it's in our DNA. Our skilled
                                    professionals offer top quality workmanship, exceptional customer service, and
                                    personalized guidance and follow-up throughout your home remodeling project.
                                </p>
                                <p>
                                    With more than a quarter of a century in the home remodeling industry, our skilled
                                    remodeling specialists have what it takes to make your home the home of your dreams.
                                    Our top-of-the-line top-quality materials are made in the USA by top-industry
                                    leaders, and all products and labor are covered under warranty. Aspen Home
                                    Remodeling also offers free virtual and in-person estimates and budget-friendly easy
                                    financing options to make your home renovation project a reality.
                                </p>
                                <p>
                                    At Aspen Home Remodeling, we pride ourselves on our relationship-based services.
                                    We're grateful for our repeat customers and those who refer us - good news travels
                                    fast! We don't just want to be your home remodeling company, we want to be the home
                                    remodeling company you tell your friends, neighbors, and relatives to call, too.
                                </p>
                                <h2>Latest Replacement Windows Projects</h2>
                                <div class="row mx-auto px-2 py-0">

                                    <div class="col-xl-4 col-md-6 col-12 mx-auto p-1">
                                        <div class="card h-100 project-cards">
                                            <picture>
                                                <img
                                                    src="/fbm-data/images/projects/home-remodeling-project-oradell-nj-after.webp"
                                                    alt="Before & After Home Remodeling Project in Oradell, NJ"
                                                    class="card-img-top"/>
                                            </picture>
                                            <div class="card-body">
                                                <div class="project-title">
                                                    <Link to="/projects/before-after-home-remodeling-project-in-oradell-nj">Before
                                                        & After Home Remodeling Project in Oradell, NJ</Link>
                                                </div>
                                                <p class="my-2">Removal of wood shakes, old wood windows, an old entry
                                                    door, old wood garage doors, and gutters. Remodeled home with
                                                    Insulated Vinyl Siding, new black vinyl windows, a new fiberglass
                                                    entry door, new [&hellip;]</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-md-6 col-12 mx-auto p-1">
                                        <div class="card h-100 project-cards">
                                            <picture>
                                                <img
                                                    src="/fbm-data/images/projects/replacement-windows-surf-city-nj-after.webp"
                                                    alt="Replacement Windows Project in Surf City, NJ"
                                                    class="card-img-top"/>
                                            </picture>
                                            <div class="card-body">
                                                <div class="project-title">
                                                    <Link to="/projects/replacement-windows-project-in-surf-city-nj">Replacement
                                                        Windows Project in Surf City, NJ</Link>
                                                </div>
                                                <p class="my-2">Aspen Home Remodeling completed a recent project of
                                                    furnished and installed new energy efficient vinyl double-hung
                                                    windows.


                                                    Replacement Windows
                                                    Vinyl Double-Hung Windows
                                                    Windows
                                                    Window Types

                                                    [&hellip;]</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>



                    <FooterUpper/>
                </main>
            </div>
        </div>
    );
}

export default ReplacementWindows;