import React from "react";
import {Link, withRouter} from "react-router-dom";
import {FooterUpper} from "./index";

class RidgewoodRoofingContractor extends React.Component{
    render() {
        return (
            <div>
                <div id="banner">
                    <div className="bg-picture-container">
                        <picture>
                            <source media="(max-width:568px)" type="image/webp"
                                    srcSet="/fbm-data/images/layout/banner/banner-interior-m.webp"/>
                                <source type="image/webp" srcSet="/fbm-data/images/layout/banner/banner-interior.webp"/>
                                    <img src="/fbm-data/images/layout/banner/banner-interior.jpg"
                                         className="bg-picture-center" alt="remodeling company"/>
                        </picture>
                    </div>
                </div>
                <div id="page">
                    <div className="container-fluid my-5">
                        <div className="row">
                            <div className="col-12 col-xl-8 mx-auto">
                                <div id="cms-breadcrumb" className="minimal-breadcrumb d-lg-flex d-none">
                                    <div className="trail">
                                        <Link title="Home" to="/">Home</Link>
                                    </div>

                                    <div className="trail">
                                        <Link title="Service Areas" to="/near-me">Service Areas</Link>
                                    </div>

                                    <div className="trail">
                                        <Link title="Ridgewood, NJ" to="/near-me/ridgewood-roofing-contractor">Ridgewood,
                                            NJ</Link>
                                    </div>

                                </div>
                                <div id="content">
                                    <h1 className="">Ridgewood's Leader in Roofing Contractor Services</h1>
                                    <picture>
                                        <source type="image/webp"
                                                srcSet="/fbm-data/images/cities/ridgewood-replacement-windows.webp"/>
                                            <img alt="Ridgewood replacement windows"
                                                 src="/fbm-data/images/cities/ridgewood-replacement-windows.jpg"
                                                 className="img-r-dynamic"/>
                                    </picture>
                                    <p>
                                        We understand what your Ridgewood home means to you, which is why your friends
                                        and neighbors in Oradell, Paramus, Wyckoff, and the surrounding communities
                                        trust Aspen Home Remodeling as the remodeling and roofing contractor for all of
                                        their home remodeling needs. With over a quarter-century of home remodeling
                                        experience, you can count on us to treat your home with the same care and
                                        concern that we'd treat our own.
                                    </p>
                                    <p>
                                        We're your full-service <Link to="/">roofing contractor in
                                        Ridgewood,</Link> offering a variety of services to enhance and fortify your roof,
                                        including:
                                    </p>
                                    <ul className="">
                                        <li>
                                            Roof repairs
                                        </li>
                                        <li>
                                            Roof replacement
                                        </li>
                                        <li>
                                            Emergency repairs
                                        </li>
                                        <li>
                                            Gutter installations
                                        </li>
                                    </ul>
                                    <p>
                                        <strong>Aspen Home Remodeling doesn't want to just be the roofing contractor you
                                            call, we want to be the roofing contractor you tell your friends, neighbors,
                                            and relatives in Ridgewood and the surrounding communities to call!</strong>
                                    </p>
                                    <h2 className=""> Stylish Roof Type Options, Made In The USA </h2>
                                    <p>
                                        We also understand the importance of supporting local businesses and buying
                                        American-made products. Aspen Home Remodeling is the area's leading local
                                        roofing contractor, and we serve an increasing number of your friends and
                                        associates in the northeastern New Jersey region. We'll guide you through the
                                        process from start to finish with our relationship-based service and our
                                        professional know-how.
                                    </p>
                                    <p>
                                        We work with some of the country's top-rated manufacturers in the roofing
                                        industry to deliver durable roofing materials that are made in the USA to
                                        exacting standards for quality and longevity. Whether you're balancing style and
                                        budget with architectural shingles, investing in the high-end look of designer
                                        dimensional shingles, or choose the classic style and durability of metal
                                        roofing, Aspen Home Remodeling offers a variety of <Link to="/roof-types">roof
                                        types</Link> to fit your needs.
                                    </p>
                                    <h2 className="">Ridgewood's Affordable Roofing Contractor</h2>
                                    <p>
                                        At Aspen Home Remodeling, we offer free in-person and virtual estimates and
                                        honest, up-front pricing on all of our roofing and remodeling projects. Whether
                                        you're an established homeowner looking to make a long-overdue change or a young
                                        couple starting in your first home, our unbeatable down-the-line prices and
                                        affordable finance options will fit any lifestyle and budget. Our home
                                        remodeling professionals will make your home remodeling dreams come true.
                                    </p>
                                    <h2 className=""> From Replacement Windows To Siding, We'll Make Your Dreams A
                                        Reality For Your Ridgewood Home! </h2>
                                    <p>
                                        As Ridgewood's premier remodeling and roofing contractor, we offer a complete
                                        line of exterior remodeling services to transform the look of your Ridgewood
                                        area home. Updating your home's exterior doesn't only enhance the curb appeal of
                                        your home, but it provides a number of other benefits, including:
                                    </p>
                                    <ul className="">
                                        <li>
                                            Increased property values
                                        </li>
                                        <li>
                                            Reduces heat loss
                                        </li>
                                        <li>
                                            Lowers fuel bills
                                        </li>
                                        <li>
                                            Reduces outside noise
                                        </li>
                                    </ul>
                                    <p>
                                        From roofing materials to windows to vinyl siding, all of the products we use
                                        are proudly made in the USA and are verified by recognized industry entities
                                        such as the Vinyl Siding Institute and Energy Star®, and your top-quality home
                                        remodeling products are installed by a team of trained professionals with over a
                                        quarter-century in the industry.
                                    </p>
                                    <p>
                                        A few of the many home remodeling services we offer include:
                                    </p>
                                    <ul className="">
                                        <li>
                                            Roof repairs &amp; roof replacement
                                        </li>
                                        <li>
                                            Gutters, shutters, &amp; trim
                                        </li>
                                        <li>
                                            Entry doors
                                        </li>
                                        <li>
                                            James Hardie &amp; vinyl siding
                                        </li>
                                        <li>
                                            Custom &amp; replacement windows
                                        </li>
                                        <li>
                                            And more!
                                        </li>
                                    </ul>
                                    <p>
                                        Aspen Home Remodeling is proud to be the remodeling and roofing contractor your
                                        friends and neighbors in Ridgewood and the surrounding Bergen County communities
                                        call for all of their home remodeling needs - and we'd love to add you to our
                                        growing list of satisfied customers. We offer relationship-based follow-up
                                        before, during, and after the job; quick response times; clean job sites; and
                                        skilled, professional, and courteous staff.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-xl-8 mx-auto">
                                <div className="text-center">
                                    <div className="my-3">
                                        <a rel="noreferrer" className="btn btn-more" target="_blank" href="https://www.ridgewoodnj.net/">Ridgewood,
                                            New Jersey</a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-6 mx-auto">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe title="map" className="embed-responsive-item"
                                                    src="//maps.google.com/?q=Ridgewood+New+Jersey&output=embed"></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="my-5">
                                    <div className="testimonials-container">
                                        <div className="row">
                                            <div className="col-12"><h2>Reviews & Testimonials on Aspen Home Remodeling
                                                in Ridgewood, NJ</h2></div>
                                            <div className="w-100"></div>
                                            <div className="col-12"><p>
                                                There are currently no reviews or testimonials for <em>Ridgewood,
                                                NJ</em>. Check back soon!
                                            </p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <FooterUpper/>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(RidgewoodRoofingContractor);
