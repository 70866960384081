import React from "react";
import {Link} from "react-router-dom";
import {FooterUpper} from "./index";
function WindowCompany() {
    return (
        <div>
            <div id="banner">
                <div class="bg-picture-container">
                    <picture>
                        <source media="(max-width:568px)" type="image/webp" srcset="/fbm-data/images/layout/banner/banner-interior-m.webp"/>
                            <source type="image/webp" srcset="/fbm-data/images/layout/banner/banner-interior.webp"/>
                                <img src="/fbm-data/images/layout/banner/banner-interior.jpg" class="bg-picture-center" alt="remodeling company"/>
                    </picture>
                </div>
            </div>
            <div id="page">
                <main class="container-fluid" id="content">
                    <div class="row bg-white">
                        <div class="col-xl-8 col-lg-10 col-12 mx-auto p-xl-5 py-5">
                            <div class="p-0 p-lg-5">
                                <div id="cms-breadcrumb" class="minimal-breadcrumb d-lg-flex d-none">
                                    <div class="trail">
                                        <Link title="Home" to="/">Home</Link>
                                    </div>

                                    <div class="trail">
                                        <Link title="Windows" to="/window-company">Windows</Link>
                                    </div>

                                </div>
                                <h1 class="">Bergen County's Prime Window Company</h1>
                                <p>
                                    When it comes to hiring a window company for your River Edge home, trust the name your friends and neighbors know and rely on for all of their home improvement needs - Aspen Home Remodeling. We're Bergen County's top-rated window company, offering a wide range of energy-efficient custom window options, including vinyl windows, skylights, sliders, and double-hung windows.
                                </p>
                                <p>
                                    New windows can transform the look of your River Edge home. Windows professionally installed by a reputable window company like Aspen Home Remodeling can provide you with a number of benefits including:
                                </p>
                                <ul class="">
                                    <li class="">Keeping your house warmer</li>
                                    <li class="">Lowering fuel bills</li>
                                    <li class="">Noise reduction</li>
                                    <li class="">Enhanced curb appeal</li>
                                    <li class="">Elevated property values</li>
                                </ul>
                                <p>
                                    We don't just want to be your window company, we want to be the window company you tell your friends, your neighbors, and your relatives to call, too!
                                </p>
                                <p>
                                    We understand what your home means to you, and you can count on Aspen Home Remodeling to treat your home with the same care and concern that we'd treat our own. We appreciate the word-of-mouth advertising our previous and repeat customers share with their friends and neighbors and we work hard to live up to our reputation for being the leading window company in River Edge and the surrounding communities.
                                </p>
                                <p>
                                    Aspen Home Remodeling offers budget-friendly financing options, personalized consultations, and skilled and courteous professionals on every project. When you're in the market for <Link to="/">River Edge window replacement</Link>, we're ready to add you to our ever-growing list of satisfied customers in River Edge and communities throughout northeast New Jersey. Call us today to schedule a free in-person or virtual estimate with the pros at Aspen Home Remodeling.
                                </p>

                                <hr/>
                                    <div class="row align-items-center p-3">
                                        <div class="col-md-4 col-12">
                                            <div class="text-center p-3">
                                                <Link title="Replacement Windows" to="/replacement-windows">
                                                    <picture>
                                                        <source type="image/webp" srcset="/fbm-data/images/windows/replacement-windows.webp"/>
                                                            <img alt="Replacement windows" src="/fbm-data/images/windows/replacement-windows.jpg" class=""/>
                                                    </picture>
                                                </Link>
                                            </div>
                                        </div>
                                        <div class="col-md-8 col-12">
                                            <h2 class="f-second text-secondary"><Link to="/replacement-windows">Replacement Windows</Link></h2>
                                            <p>
                                                <Link to="/replacement-windows">Replacement windows</Link> are designed to retrofit a wide array of homes, providing updated appeal and a revitalized appearance for your River Edge residence. Our stylish and well-built options are energy efficient and durable and will provide your home with lasting beauty for years to come.
                                            </p>
                                            <Link to="/replacement-windows" class="btn btn-more">Read More</Link>
                                        </div>
                                    </div>
                                    <hr/>
                                        <div class="row align-items-center p-3">
                                            <div class="col-md-4 col-12">
                                                <div class="text-center p-3">
                                                    <Link title="Window Types" to="/window-types">
                                                        <picture>
                                                            <source type="image/webp" srcset="/fbm-data/images/windows/window-types.webp"/>
                                                                <img alt="Window types" src="/fbm-data/images/windows/window-types.jpg" class="w-100"/>
                                                        </picture>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div class="col-md-8 col-12">
                                                <h2 class="f-second text-secondary"><Link to="/window-types">Window Types</Link></h2>
                                                <p>
                                                    Our custom vinyl windows will elevate your home's aesthetic while providing you with advanced features like easy-tilt cleaning and noise reduction. From our double-hung windows to sliders and custom windows, we'll make your window on the world a little nicer.
                                                </p>
                                                <Link to="/window-types" class="btn btn-more">Read More</Link>
                                            </div>
                                        </div>
                                        <hr/>

                                            <h2 class=""> Professional Remodeling Services To Elevate Your Home's Appeal </h2>
                                            <p>
                                                Aspen Home Remodeling offers a complete line of exterior remodeling services for your River Edge area home, including:
                                            </p>
                                            <ul class="">
                                                <li class="">Roof repairs &amp; remodeling</li>
                                                <li class="">James Hardie &amp; vinyl siding</li>
                                                <li class="">Windows</li>
                                                <li class="">Entry doors</li>
                                                <li class="">Gutters &amp; trim</li>
                                                <li class="">Shutters</li>
                                            </ul>
                                            <p>
                                                Home exterior remodeling isn't just our job, it's in our DNA. We understand what your home means to you, which is why we treat your home with the same care and concern we'd treat our own. Our skilled professionals offer top-notch customer service with guidance and follow-up throughout the project.
                                            </p>
                                            <p>
                                                We have more than 25 years of experience in the home remodeling industry, and our skilled professionals take pride in their workmanship on every project. We provide top-of-the-line top-quality products, all made in the USA by industry leaders, and all products and labor are covered under warranty. Aspen home remodeling also offers free virtual and in-person estimates and budget-friendly easy financing options to help you make your home improvement dreams come true.
                                            </p>
                                            <p>
                                                It's hard to beat the relationship-based services that we provide at Aspen Home Remodeling. We don't just want to be your home remodeling company, we want to be the home remodeling company you tell your friends, neighbors, and relatives to call, too. Call us today to start making your home remodeling dreams come true.
                                            </p>
                            </div>
                        </div>
                    </div>


                    <FooterUpper/>
                </main>
            </div>
        </div>
    );
}
export default WindowCompany;