import React from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {Redirect, withRouter} from "react-router-dom";
import Recaptcha from "react-recaptcha";


class ContactForm extends React.Component{
    constructor(props) {
        super(props);
        this.state = {isContact: false};
    }
    componentDidMount() {
        const script = document.createElement("script");
        script.src =
            "https://www.google.com/recaptcha/api.js";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
    }
    encode(data) {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&")
    }

    render() {
        const isContact = this.state.isContact;
        if (!isContact){
            return(
                <Formik
                    initialValues={{ fullname: '',email:'',recaptcha: "",phone: "",services: "", }}
                    validate={values => {
                        const errors = {};
                        if (!values.email) {
                            errors.email = 'Required';
                        } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                        ) {
                            errors.email = 'Invalid email address';
                        }
                        if (!values.fullname){
                            errors.fullname = 'Required';
                        }
                        if (!values.phone){
                            errors.phone = 'Required';
                        }
                        // if (!values.services){
                        //     errors.services = 'Required';
                        // }
                        // if (!values.recaptcha){
                        //     errors.recaptcha = 'Required';
                        // }
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        console.log(values);
                        const data = {
                            fullname: values.fullname,
                            email: values.email,
                            phone: values.phone,
                            services: values.services,
                            "g-recaptcha-response": values.recaptcha,
                            "form-name": "contactForm",
                        };
                        fetch("/", {
                            method: "POST",
                            headers: { "Content-Type": "application/x-www-form-urlencoded" },
                            body: this.encode(data)
                        }).then(() => console.log('netlify Complete')).catch(error => alert(error))
                        fetch('/netlify/functions/contact-us.js', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(data),
                        }).then((res) => res.json())
                            .then(result => {
                                console.log(result);
                                if (result.success){
                                    this.setState({isContact: true});
                                }
                                // window.location.href = result.order.url;
                            })
                            .catch(error => console.log('error', error));
                        // setTimeout(() => {
                        //     alert(JSON.stringify(values, null, 2));
                        //     setSubmitting(false);
                        // }, 400);
                    }}
                >
                    {({ isSubmitting,setFieldValue,errors,touched,load }) => (
                        <Form data-netlify="true"
                              data-netlify-honeypot="bot-field"
                              data-netlify-recaptcha="true" name="contactForm" action="/">
                            <Field type="hidden" name="form-name" value="contactForm"/>
                            <div className="form-group">
                                <label style={{color:'white'}} htmlFor="fullname">Full Name</label>
                                <Field type="text" name="fullname" className="form-control" />
                                <ErrorMessage style={{color:'red'}} name="fullname" component="div" />
                            </div>
                            <div className="form-group">
                                <label style={{color:'white'}} htmlFor="email">Email</label>
                                <Field type="email" name="email" className="form-control"/>
                                <ErrorMessage style={{color:'red'}} name="email" component="div" />
                            </div>
                            <div className="form-group">
                                <label style={{color:'white'}} htmlFor="phone">Phone Number</label>
                                <Field type="text" name="phone" className="form-control" />
                                <ErrorMessage style={{color:'red'}} name="phone" component="div" />
                            </div>
                            <div className="form-group">
                                <label style={{color:'white'}} htmlFor="services">Services</label>
                                <Field type="textarea" name="services" className="form-control" />
                                <ErrorMessage style={{color:'red'}} name="services" component="div" />
                            </div>
                            <div className="form-group">
                                <Recaptcha
                                    sitekey="6LeTRVgbAAAAACuqpcgLxE0mcnscWbj4fZRRGikP"
                                    render="explicit"
                                    theme="light"
                                    verifyCallback={(response) => { setFieldValue("recaptcha", response); }}
                                    onloadCallback={() => { console.log("done loading!"); }}
                                />
                                {errors.recaptcha
                                && touched.recaptcha && (
                                    <p style={{color:'red'}}>{errors.recaptcha}</p>
                                )}
                            </div>
                            <div className="form-group">
                                <button disabled={isSubmitting} style={{backgroundColor:'rgb(220, 54, 54)'}} name="submit" type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            );
        }else {
            return <Redirect to="/thankyou" />
        }
    }
}
export default withRouter(ContactForm);