import React from "react";
import {Link} from "react-router-dom";
import {FooterUpper} from "./index";
function NearMe() {
    return (
        <div>
            <div id="banner">
                <div class="bg-picture-container">
                    <picture>
                        <source media="(max-width:568px)" type="image/webp" srcset="/fbm-data/images/layout/banner/banner-interior-m.webp"/>
                            <source type="image/webp" srcset="/fbm-data/images/layout/banner/banner-interior.webp"/>
                                <img src="/fbm-data/images/layout/banner/banner-interior.jpg" class="bg-picture-center" alt="remodeling company"/>
                    </picture>
                </div>
            </div>
            <div id="page">
                <main class="container-fluid" id="content">
                    <div class="row bg-white">
                        <div class="col-xl-8 col-lg-10 col-12 mx-auto p-xl-5 py-5">
                            <div class="p-0 p-lg-5">
                                <div id="cms-breadcrumb" class="minimal-breadcrumb d-lg-flex d-none">
                                    <div class="trail">
                                        <Link title="Home" to="/">Home</Link>
                                    </div>

                                    <div class="trail">
                                        <Link title="Service Areas" to="/near-me">Service Areas</Link>
                                    </div>

                                </div>
                                <h1>Service Areas For Aspen Home Remodeling</h1>
                                <p>
                                    Below is a list of service areas where we provide roofing contractor services. If you don't see your area listed below, please give us a call. Our roofing contractor experts are here to provide you with superb service for your River Edge area home.
                                </p>

                                <div class="grid grid-xl-5 grid-gap-3 mb-3">
                                    <Link to="/near-me/roofing-contractor-emerson" class="btn btn-more" >Emerson, NJ</Link> <Link to="/near-me/roofing-contractor-glen-rock" class="btn btn-more" >Glen Rock, NJ</Link> <Link to="/near-me/roofing-contractor-hackensack" class="btn btn-more" >Hackensack, NJ</Link> <Link to="/near-me/roofing-contractor-mahwah" class="btn btn-more" >Mahwah, NJ</Link> <Link to="/near-me/oradell-roofing-contractor" class="btn btn-more" >Oradell, NJ</Link> <Link to="/near-me/paramus-roofing-contractor" class="btn btn-more" >Paramus, NJ</Link> <Link to="/near-me/roofing-contractor-ramsey" class="btn btn-more" >Ramsey, NJ</Link> <Link to="/near-me/ridgewood-roofing-contractor" class="btn btn-more" >Ridgewood, NJ</Link> <Link to="/near-me/river-edge-roofing-contractor" class="btn btn-more" >River Edge, NJ</Link> <Link to="/near-me/wyckoff-roofing-contractor" class="btn btn-more" >Wyckoff, NJ</Link>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-xl-8 mx-auto">
                                        <div class="embed-responsive embed-responsive-16by9">
                                            <iframe title="NearMe" class="embed-responsive-item" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3012.7012975099533!2d-74.03174168383046!3d40.96612202996537!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2fbf57e6b2941%3A0x5d27f71ef2dcb496!2sAspen%20Home%20Remodeling%2C%20LLC.!5e0!3m2!1sen!2sus!4v1616002338327!5m2!1sen!2sus"></iframe>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                    <FooterUpper/>
                </main>
            </div>
        </div>
    );
}
export default NearMe;