import React from "react";
import {Link} from "react-router-dom";
import {ContactForm, FooterUpper} from "./index";
function Home() {
    return (
        <div>
            <div className="container-fluid">
                <div className="row bg-white">
                    <div className="col-12 mx-auto p-0">
                        <div className="bg-picture-container p-0">
                            <picture>
                                <source media="(max-width:568px)" type="image/webp"
                                        srcSet="/fbm-data/images/layout/banner/banner-main-m.webp"/>
                                    <source type="image/webp" srcSet="/fbm-data/images/layout/banner/banner-main.webp"/>
                                        <img src="/fbm-data/images/layout/banner/banner-main.jpg" alt="remodeling banner"
                                             className="w-100"/>
                            </picture>
                        </div>
                    </div>
                </div>
            </div>
            <main className="container-fluid" id="content">

                <div className="row bg-light big-wrap mx-auto py-3">
                    <div className="col-lg-8 col-12 mx-auto p-5">
                        <div className="p-0 p-lg-5">
                            <h1 className="med text-primary text-uppercase mb-0 pb-0">
                                Bergen County's Premier
                                <br/>
						<span className="f-second sml text-gray">
							Roofing, Windows, &amp; Siding Professionals
						</span>
                            </h1>
                            <p>
                                <strong>Aspen Home Remodeling is the top-quality roofing contractor your friends and neighbors
                                    in River Edge and the surrounding communities know and trust for all of their exterior home
                                    remodeling projects.</strong>
                            </p>
                            <p>
                                We understand what your home means to you, which is why you can always count on the
                                professionals at Aspen Home Remodeling to treat your home as if it were our own. As the premier
                                roofing contractor in Bergen County, we pride ourselves on our commitment to excellence, and our
                                customers rave about our quick response times, our follow-up during and after the job, the
                                cleanliness of our job sites, and our skilled and courteous staff.
                            </p>
                            <p>
                                When you want a roofing contractor who will provide you with personalized relationship-based
                                service, Aspen Home Remodeling is the one to call. We proudly serve the River Edge area for all
                                of your roofing, window, and siding needs. Call us today or complete our online request form to
                                start making your home remodeling dreams come true.
                            </p>
                            <div className="d-block d-md-flex justify-content-start">
                                <div className="mb-1 mb-md-0 mr-0 mr-md-1">
                                    <Link to="/contact-us" className="btn btn-block btn-banner">Schedule Consultation</Link>
                                </div>
                                <div>
                                    <Link to="tel:+1-2019945354" className="btn btn-block btn-banner">Call 201-994-5354</Link>
                                </div>
                            </div>
                            <div className="mt-1 d-block d-md-flex justify-content-start">
                                <div className="mb-1 mb-md-0 mr-0 mr-md-1">
                                    <Link to="https://www.google.com/search?q=Aspen+Home+Remodeling&#lrd=0x89c2fbf57e6b2941:0x5d27f71ef2dcb496,1,,,"
                                       className="btn btn-block btn-banner">See Google Reviews</Link>
                                </div>
                                <div>
                                    <Link to="/about-us" className="btn btn-block btn-md-flex btn-more">Learn More</Link>
                                </div>
                            </div>
                            <br clear="all"/>
                            <h2 className="text-center">Financing Available</h2>
                            <p className="text-center">
                                <a href="https://app.gethearth.com/partners/aspen-home-remodeling-llc?utm_campaign=18871&utm_content=general&utm_medium=contractor-website&utm_source=contractor&utm_term=150_150"
                                   target="_blank" rel="noreferrer">
                                    <img
                                        src="https://app.gethearth.com/contractor_images/aspen-home-remodeling-llc/general/150x150/hearth.png"
                                        alt="hearth financing 150_150" style={{height:'150px',width:'150px'}}/></a>
                            </p>
                            <p className="text-center f-second sml"><strong>New Milford. Another happy Aspen Home Remodeling
                                customer!!</strong></p>
                            <div className="text-center">
                                <img alt="New milford happy customer aspenhomeremodeling"
                                     src="/fbm-data/images/new-milford-happy-customer-aspenhomeremodeling.jpg" className=""/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 mx-auto p-5" id="contact-form">
                        <h2 className="text-center text-light ">Get a FREE Consultation Today!</h2>
                        <div id="wufoo-qis2wzj1k0ued3">
                            <ContactForm/>
                        </div>
                    </div>
                </div>

                <div className="row bg-white">
                    <div className="order-1 col-xl-5 col-lg-4 col-12 mx-auto p-0" id="bg-roofing">
                        <div className="bg-picture-container p-0">
                            <picture>
                                <source media="(max-width:568px)" type="image/webp"
                                        srcSet="/fbm-data/images/layout/banner/bg-roofing-m.webp"/>
                                    <source type="image/webp" srcSet="/fbm-data/images/layout/banner/bg-roofing.webp"/>
                                        <img src="/fbm-data/images/layout/banner/bg-roofing.jpg" className="bg-picture"
                                             alt="roofing services"/>
                            </picture>
                        </div>
                    </div>
                    <div className="order-2 col-xl-7 col-lg-8 col-12 align-self-center mx-auto p-5">
                        <div className="p-0 p-lg-5">
                            <h2 className="med text-primary text-uppercase mb-0 pb-0">
                                From Roof Repairs to Replacement
                                <br/>
						<span className="f-second sml text-gray">
							We Provide Only the Best Roof Materials
						</span>
                            </h2>
                            <p className="mx-0 my-3">
                                We're your full-service roofing contractor in River Edge, and we're pleased to provide a full
                                line of roofing services - from minor repairs to complete roof replacement. We offer limited
                                lifetime warranties on all of our materials, a 10-year warranty on all labor, and have over 25
                                years of experience in the field - you could say that home repairs are in our DNA!
                            </p>
                            <div className="row mx-auto">
                                <div className="col-4 text-center text-sm-left mx-auto p-3">
                                    <Link to="https://www.tamko.com/">
                                        <picture>
                                            <source type="image/webp" srcSet="/fbm-data/images/layout/brands/logo-tamko.webp"/>
                                                <img src="/fbm-data/images/layout/brands/logo-tamko.png" alt="Tamko Logo"/>
                                        </picture>
                                    </Link>
                                </div>
                                <div className="col-4 text-center text-sm-left mx-auto p-3">
                                    <Link to="https://www.gaf.com/en-us">
                                        <picture>
                                            <source type="image/webp" srcSet="/fbm-data/images/layout/brands/logo-gaf.webp"/>
                                                <img src="/fbm-data/images/layout/brands/logo-gaf.png" alt="GAF Logo"/>
                                        </picture>
                                    </Link>
                                </div>
                                <div className="col-4 text-center text-sm-left mx-auto p-3">
                                    <Link to="https://www.owenscorning.com/en-us">
                                        <picture>
                                            <source type="image/webp" srcSet="/fbm-data/images/layout/brands/logo-owens.webp"/>
                                                <img src="/fbm-data/images/layout/brands/logo-owens.png"
                                                     alt="Owens Corning Logo"/>
                                        </picture>
                                    </Link>
                                </div>
                            </div>
                            <div className="mt-3">
                                <Link to="/roofing" className="btn btn-more">Learn More</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row bg-light">
                    <div className="order-1 order-lg-2 col-xl-5 col-lg-4 col-12 mx-auto p-0" id="bg-siding">
                        <div className="bg-picture-container p-0">
                            <picture>
                                <source media="(max-width:568px)" type="image/webp"
                                        srcSet="/fbm-data/images/layout/banner/bg-siding-m.webp"/>
                                    <source type="image/webp" srcSet="/fbm-data/images/layout/banner/bg-siding.webp"/>
                                        <img src="/fbm-data/images/layout/banner/bg-siding.jpg" className="bg-picture"
                                             alt="siding"/>
                            </picture>
                        </div>
                    </div>
                    <div
                        className="order-2 order-lg-1 col-xl-7 col-lg-8 col-12 align-self-center text-left text-lg-right mx-auto p-5">
                        <div className="p-0 p-lg-5">
                            <h2 className="med text-primary text-uppercase mb-0 pb-0">
                                Vinyl Siding
                                <br/>
						<span className="f-second sml text-gray">
							&amp; James Hardie Siding
						</span>
                            </h2>
                            <p className="mx-0 my-3">
                                We offer a wide array of siding options for your River Edge home, including vinyl siding and
                                sturdy James Hardie Siding. With a number of color options and design choices, the pros at Aspen
                                Home Remodeling will help you choose a siding selection that will add value and appeal to your
                                home and make you the envy of your River Edge neighborhood.
                            </p>
                            <p className="mx-0 my-3">
                                New siding can not only enhance your curb appeal and give you a custom design look for your
                                home, but siding can save you big money on energy costs - and durable siding maintains its
                                finish for years, so it also saves you having to repaint your home's exterior. Aspen Home
                                Remodeling also offers convenient financing options to make your home improvement dreams a
                                reality!
                            </p>
                            <div className="row mx-auto">
                                <div className="col-lg-2 col-4 text-center mx-auto p-3">
                                    <Link to="https://www.plygem.com/siding/brands/mastic/">
                                        <picture>
                                            <source type="image/webp" srcSet="/fbm-data/images/layout/brands/logo-mastic.webp"/>
                                                <img src="/fbm-data/images/layout/brands/logo-mastic.png" alt="Mastic Logo"/>
                                        </picture>
                                    </Link>
                                </div>
                                <div className="col-lg-2 col-4 text-center mx-auto p-3">
                                    <Link to="https://www.jameshardie.com/">
                                        <picture>
                                            <source type="image/webp" srcSet="/fbm-data/images/layout/brands/logo-hardie.webp"/>
                                                <img src="/fbm-data/images/layout/brands/logo-hardie.png"
                                                     alt="James Hardie Logo"/>
                                        </picture>
                                    </Link>
                                </div>
                                <div className="col-lg-2 col-4 text-center mx-auto p-3">
                                    <Link to="https://www.provia.com/">
                                        <picture>
                                            <source type="image/webp" srcSet="/fbm-data/images/layout/brands/logo-provia.webp"/>
                                                <img src="/fbm-data/images/layout/brands/logo-provia.png" alt="Provia Logo"/>
                                        </picture>
                                    </Link>
                                </div>
                                <div className="col-lg-2 col-4 text-center mx-auto p-3">
                                    <Link to="https://www.royalbuildingproducts.com/">
                                        <picture>
                                            <source type="image/webp" srcSet="/fbm-data/images/layout/brands/logo-royal.webp"/>
                                                <img src="/fbm-data/images/layout/brands/logo-royal.png" alt="Royal Logo"/>
                                        </picture>
                                    </Link>
                                </div>
                                <div className="col-lg-2 col-4 text-center mx-auto p-3">
                                    <Link to="https://www.certainteed.com/">
                                        <picture>
                                            <source type="image/webp"
                                                    srcSet="/fbm-data/images/layout/brands/logo-certainteed.webp"/>
                                                <img src="/fbm-data/images/layout/brands/logo-certainteed.png"
                                                     alt="Certainteed Logo"/>
                                        </picture>
                                    </Link>
                                </div>
                                <div className="col-lg-2 col-4 text-center mx-auto p-3">
                                    <Link to="https://www.alside.com/">
                                        <picture>
                                            <source type="image/webp" srcSet="/fbm-data/images/layout/brands/logo-alside.webp"/>
                                                <img src="/fbm-data/images/layout/brands/logo-alside.png" alt="Alside Logo"/>
                                        </picture>
                                    </Link>
                                </div>
                            </div>
                            <div className="mt-3">
                                <Link to="/siding" className="btn btn-more">Learn More</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row bg-primary p-1">
                    <div className="col-lg-4 col-12 mx-auto p-1">
                        <div className="service-fadein-hover">
                            <picture>
                                <source media="(max-width:568px)" type="image/webp"
                                        srcSet="/fbm-data/images/layout/services/service-windows-m.webp"/>
                                    <source type="image/webp" srcSet="/fbm-data/images/layout/services/service-windows.webp"/>
                                        <img src="/fbm-data/images/layout/services/service-windows.jpg"
                                             alt="replacement windows"/>
                            </picture>
                            <div className="service-content">
                                <div className="service-title">
                                    Replacement Windows
                                </div>
                                <div className="service-links">
                                    <Link to="/replacement-windows" className="btn btn-service">
                                        <i className="fas fa-plus"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 mx-auto p-1">
                        <div className="service-fadein-hover">
                            <picture>
                                <source media="(max-width:568px)" type="image/webp"
                                        srcSet="/fbm-data/images/layout/services/service-roofing-m.webp"/>
                                    <source type="image/webp" srcSet="/fbm-data/images/layout/services/service-roofing.webp"/>
                                        <img src="/fbm-data/images/layout/services/service-roofing.jpg" alt="roofing"/>
                            </picture>
                            <div className="service-content">
                                <div className="service-title">
                                    Roofing
                                </div>
                                <div className="service-links">
                                    <Link to="/roofing" className="btn btn-service">
                                        <i className="fas fa-plus"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 mx-auto p-1">
                        <div className="service-fadein-hover">
                            <picture>
                                <source media="(max-width:568px)" type="image/webp"
                                        srcSet="/fbm-data/images/layout/services/service-siding-m.webp"/>
                                    <source type="image/webp" srcSet="/fbm-data/images/layout/services/service-siding.webp"/>
                                        <img src="/fbm-data/images/layout/services/service-siding.jpg" alt="siding"/>
                            </picture>
                            <div className="service-content">
                                <div className="service-title">
                                    Siding
                                </div>
                                <div className="service-links">
                                    <Link to="/siding" className="btn btn-service">
                                        <i className="fas fa-plus"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row bg-white">
                    <div className="order-2 order-lg-1 col-xl-5 col-lg-4 col-12 mx-auto p-0" id="bg-windows">
                        <div className="bg-picture-container p-0">
                            <picture>
                                <source media="(max-width:568px)" type="image/webp"
                                        srcSet="/fbm-data/images/layout/banner/bg-windows-m.webp"/>
                                    <source type="image/webp" srcSet="/fbm-data/images/layout/banner/bg-windows.webp"/>
                                        <img src="/fbm-data/images/layout/banner/bg-windows.jpg" className="bg-picture"
                                             alt="replacement windows"/>
                            </picture>
                        </div>
                    </div>
                    <div className="order-1 order-lg-2 col-xl-7 col-lg-8 col-12 align-self-center mx-auto p-5">
                        <div className="p-0 p-lg-5">
                            <h2 className="med text-primary text-uppercase mb-0 pb-0">
                                Energy Saving
                                <br/>
						<span className="f-second sml text-gray">
							Replacement Windows
						</span>
                            </h2>
                            <p className="mx-0 my-3">
                                From roof repairs to <Link to="/replacement-windows">replacement windows</Link> to siding and entry
                                doors, we're here to give your Bergen County home a fresh new appeal that can also keep your
                                house warmer, your fuel bills lower, and reduce outside noises. An added bonus - our
                                energy-saving replacement windows are easy to clean! Call us today at 201-994-5354 to schedule a
                                free estimate.
                            </p>
                            <div className="row mx-auto">
                                <div className="col-sm-3 col-6 text-center text-sm-left mx-auto p-3">
                                    <Link to="https://www.andersenwindows.com/">
                                        <picture>
                                            <source type="image/webp"
                                                    srcSet="/fbm-data/images/layout/brands/logo-andersen.webp"/>
                                                <img src="/fbm-data/images/layout/brands/logo-andersen.png"
                                                     alt="Andersen Windows Logo"/>
                                        </picture>
                                    </Link>
                                </div>
                                <div className="col-sm-3 col-6 text-center text-sm-left mx-auto p-3">
                                    <Link to="https://www.marvin.com/">
                                        <picture>
                                            <source type="image/webp" srcSet="/fbm-data/images/layout/brands/logo-marvin.webp"/>
                                                <img src="/fbm-data/images/layout/brands/logo-marvin.png" alt="Marvin Logo"/>
                                        </picture>
                                    </Link>
                                </div>
                                <div className="col-sm-3 col-6 text-center text-sm-left mx-auto p-3">
                                    <Link to="https://www.pella.com/">
                                        <picture>
                                            <source type="image/webp" srcSet="/fbm-data/images/layout/brands/logo-pella.webp"/>
                                                <img src="/fbm-data/images/layout/brands/logo-pella.png"
                                                     alt="Pella Windows and Doors Logo"/>
                                        </picture>
                                    </Link>
                                </div>
                                <div className="col-sm-3 col-6 text-center text-sm-left mx-auto p-3">
                                    <Link to="https://wincorewindows.com/">
                                        <picture>
                                            <source type="image/webp" srcSet="/fbm-data/images/layout/brands/logo-wincore.webp"/>
                                                <img src="/fbm-data/images/layout/brands/logo-wincore.png" alt="Wincore Logo"/>
                                        </picture>
                                    </Link>
                                </div>
                            </div>
                            <div className="mt-3">
                                <Link to="/window-company" className="btn btn-more">Learn More</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row bg-light">
                    <div className="order-2 col-xl-5 col-lg-4 col-12 mx-auto p-0" id="bg-articles">
                        <div className="bg-picture-container p-0">
                            <picture>
                                <source media="(max-width:568px)" type="image/webp"
                                        srcSet="/fbm-data/images/layout/banner/bg-articles-m.webp"/>
                                    <source type="image/webp" srcSet="/fbm-data/images/layout/banner/bg-articles.webp"/>
                                        <img src="/fbm-data/images/layout/banner/bg-articles.jpg" className="bg-picture"
                                             alt="articles background"/>
                            </picture>
                        </div>
                    </div>
                    <div className="order-1 col-xl-7 col-lg-8 col-12 align-self-center text-left text-lg-right mx-auto p-5">
                        <div className="p-0 p-lg-5">
                            <h2 className="med text-primary text-uppercase mb-3 pb-0">
                                Latest Projects
                            </h2>
                            <div className="row mx-auto px-2 py-0">

                                <div className="col-xl-4 col-md-6 col-12 mx-auto p-1">
                                    <div className="card h-100 project-cards">
                                        <picture>
                                            <img
                                                src="/fbm-data/images/projects/roofing-siding-new-entry-door-new-milford-nj-after.webp"
                                                alt="Roofing, Siding, & New Entry Door Project in New Milford, NJ"
                                                className="card-img-top"/>
                                        </picture>
                                        <div className="card-body">
                                            <div className="project-title">
                                                <Link to="/projects/roofing-siding-new-entry-door-project-in-new-milford-nj">Roofing,
                                                    Siding, & New Entry Door Project in New Milford, NJ</Link>
                                            </div>
                                            <p className="my-2">Aspen Home Remodeling recently completed a project of roof
                                                replacement, aluminum siding removal with new vinyl siding, and a front entry
                                                door replacement.


                                                Roof Replacement
                                                Vinyl Siding
                                                Entry D [&hellip;]</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-md-6 col-12 mx-auto p-1">
                                    <div className="card h-100 project-cards">
                                        <picture>
                                            <img src="/fbm-data/images/projects/home-remodeling-project-oradell-nj-after.webp"
                                                 alt="Before & After Home Remodeling Project in Oradell, NJ"
                                                 className="card-img-top"/>
                                        </picture>
                                        <div className="card-body">
                                            <div className="project-title">
                                                <Link to="/projects/before-after-home-remodeling-project-in-oradell-nj">Before &
                                                    After Home Remodeling Project in Oradell, NJ</Link>
                                            </div>
                                            <p className="my-2">Removal of wood shakes, old wood windows, an old entry door, old
                                                wood garage doors, and gutters. Remodeled home with Insulated Vinyl Siding, new
                                                black vinyl windows, a new fiberglass entry door, new [&hellip;]</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <FooterUpper/>
            </main>
        </div>
    );
}

export default Home;