import React from "react";
import {FooterUpper} from "./index";
function About() {
    return (
        <div>
            <div id="banner">
                <div class="bg-picture-container">
                    <picture>
                        <source media="(max-width:568px)" type="image/webp" srcset="/fbm-data/images/layout/banner/banner-interior-m.webp"/>
                            <source type="image/webp" srcset="/fbm-data/images/layout/banner/banner-interior.webp"/>
                                <img src="/fbm-data/images/layout/banner/banner-interior.jpg" class="bg-picture-center" alt="remodeling company"/>
                    </picture>
                </div>
            </div>
            <div id="page">
                <main class="container-fluid" id="content">
                    <div class="row bg-white">
                        <div class="col-xl-8 col-lg-10 col-12 mx-auto p-xl-5 py-5">
                            <div class="p-0 p-lg-5">
                                <div id="cms-breadcrumb" class="minimal-breadcrumb d-lg-flex d-none">
                                    <div class="trail">
                                        <a title="Home" href="/">Home</a>
                                    </div>

                                    <div class="trail">
                                        <a title="About Us" href="/about-us">About Us</a>
                                    </div>

                                </div>
                                <h1 class="">About Aspen Home Remodeling - Your Trusted Bergen County Remodeler</h1>
                                <picture>
                                    <source type="image/webp" srcset="/fbm-data/images/remodeling-company.webp"/>
                                        <img alt="Remodeling company" src="/fbm-data/images/remodeling-company.jpg" class="img-r-dynamic"/>
                                </picture>
                                <p>
                                    Aspen Home Remodeling is the remodeler who understands what your Oradell home means to you. We're your local hometown remodeler, a proud Oradell resident with over a quarter of a century of experience and a reputation that proves our commitment to treating your home with the same skill and care that we would treat our own.
                                </p>
                                <p>
                                    Home remodeling isn't just something we do, it's something we enjoy - home remodeling is in our DNA! Aspen Home Remodeling offers a number of exterior remodeling services for your home, including:
                                </p>
                                <ul class="">
                                    <li class="">Roof repair &amp; roof replacement</li>
                                    <li class="">Vinyl &amp; James Hardie siding</li>
                                    <li class="">Replacement windows</li>
                                    <li class="">Entry doors</li>
                                    <li class="">Gutters, trim, &amp; shutters</li>
                                </ul>
                                <p>
                                    We pride ourselves on taking care of our friends and neighbors in Oradell and the surrounding Bergen County communities, including River Edge and Paramus. Our customers rave about our follow-up during and after the job, our quick response times, the cleanliness of our worksites, and our skilled and courteous staff. We are fully licensed and insured, and count on referrals and word-of-mouth advertising - good news travels fast!
                                </p>
                                <p>
                                    <strong>We don't just want to take care of your Oradell home, we want to be the remodeler your friends, your neighbors, and your relatives call, too!</strong>
                                </p>
                                <p>
                                    Whether you need roofing, siding, or windows for your Oradell area home, we can guide you through the process from start to finish to make your vision a reality. We offer affordable financing options, free in-person and virtual estimates, and prompt turnaround times. Call the remodeler your friends and neighbors in Oradell trust for all of their exterior home improvement needs and let us make your home remodeling dreams come true.
                                </p>
                                <h2 class="">Remodeling Solutions For Any Budget</h2>
                                <p>
                                    If you're on the fence about whether you can afford to work with a remodeler, let us assuage your concerns! At Aspen Home Remodeling, we offer free in-person and virtual estimates and honest, transparent, and up-front pricing on every remodeling project. With unbeatable prices and affordable finance options to meet any budget, our remodeling professionals will make your home remodeling dreams a comfortable reality!
                                </p>
                                <h2 class=""> Local Professionals, Quality American-Made Products </h2>
                                <p>
                                    Now more than ever, we're all aware of the importance of supporting local businesses and buying American-made products. All of the products we use for our home remodeling projects, from roofing materials to windows to <a href="/vinyl-siding">vinyl siding</a> are made in the USA. Using supplies verified by the Vinyl Siding Institute and Energy Star®-Approved Products means that we're committed to providing top-quality products that are installed by a team of trained professionals.
                                </p>
                                <h2 class=""> Superior Health &amp; Safety Standards </h2>
                                <p>
                                    At Aspen Home Remodeling, we're dedicated to ensuring the safety and well-being of our customers and staff. We offer virtual estimates for your peace of mind. Our crew members follow all federal, state, and local guidelines and maintain safety protocols, including wearing masks, following sanitizing practices, and performing proper disinfection of all tools and materials while we're working in and around your home. We care about our neighbors and our community!
                                </p>
                                <h2 class=""> Oradell's Top-Rated Roofing Contractor </h2>
                                <p>
                                    We're the top-rated <a href="/">roofing contractor in Oradell</a>, serving an ever-growing number of satisfied customers in Northeast New Jersey from Mahwah to Hackensack. We work with some of the best manufacturers in the industry to deliver durable American-made roofing materials and long-lasting results for our clients.
                                </p>
                                <h2 class=""> Turn Your Oradell Home Into Your Dream Home! </h2>
                                <p>
                                    At Aspen Home Remodeling, we're here to make your vision a reality. Whether you're a first-time homeowner just starting out or have been promising to get to those exterior remodeling projects "someday" for the last few decades, we're the remodeler you can trust to listen to your ideas, provide guidance and recommendations, and make your remodeling dreams come true!
                                </p>
                            </div>
                        </div>
                    </div>
                    <FooterUpper/>
                </main>
            </div>
        </div>
    );
}
export default About;