import React from "react";
import {Link} from "react-router-dom";
import {FooterUpper} from "./index";

class RoofingContractorTips extends React.Component{
    constructor(props) {
        super(props);
        this.state = {data: []};
    }

    componentDidMount() {
        this.getArticles();
    }

    getArticles = async () => {
        const data = await fetch("/.netlify/functions/get-articles")
            .then((res) => res.json())
            .catch((err) => console.error(err));
        this.setState({data})
    }
    render() {
        return (
            <div>
                <div id="banner">
                    <div class="bg-picture-container">
                        <picture>
                            <source media="(max-width:568px)" type="image/webp"
                                    srcset="/fbm-data/images/layout/banner/banner-interior-m.webp"/>
                            <source type="image/webp" srcset="/fbm-data/images/layout/banner/banner-interior.webp"/>
                            <img src="/fbm-data/images/layout/banner/banner-interior.jpg" class="bg-picture-center"
                                 alt="remodeling company"/>
                        </picture>
                    </div>
                </div>
                <div id="page">
                    <main class="container-fluid" id="content">
                        <div class="row bg-white">
                            <div class="col-xl-8 col-lg-10 col-12 mx-auto p-xl-5 py-5">
                                <div class="p-0 p-lg-5">
                                    <div id="cms-breadcrumb" class="minimal-breadcrumb d-lg-flex d-none">
                                        <div class="trail">
                                            <Link title="Home" to="/">Home</Link>
                                        </div>

                                        <div class="trail">
                                            <Link title="Articles" to="/roofing-contractor-tips">Articles</Link>
                                        </div>

                                    </div>
                                    <h1 class="">Roofing Contractor Tips and Articles For Your Home</h1>

                                    <div paginate elements-per-page="6" columns="1" classes="" paginator-classes="my-2"
                                         paginator-button-classes="btn btn-more" paginator-location="top">
                                        {
                                            this.state.data.map(item =>
                                                <article>
                                                    <hr/>
                                                    <div className="row align-items-center p-3">
                                                        <div className="col-md-4 col-12">
                                                            <div className="text-center p-3">
                                                                <img className=""
                                                                     alt={item.title}
                                                                     src={item.image}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8 col-12">
                                                            <h2 className=""><Link class=""
                                                                                   to={"/roofing-contractor-tips/"+item.slug}>{item.title}</Link></h2>
                                                            <p>{item.short}
                                                                [&hellip;]</p>
                                                        </div>
                                                    </div>
                                                </article>
                                            )
                                        }
                                    </div>
                                    <hr/>

                                </div>
                            </div>
                        </div>


                        <FooterUpper/>
                    </main>
                </div>
            </div>
        );
    }
}

export default RoofingContractorTips;