import React from "react";
import {Link} from "react-router-dom";
import {FooterUpper} from "./index";

function JamesHardieSiding() {
    return (
        <div>
            <div id="banner">
                <div class="bg-picture-container">
                    <picture>
                        <source media="(max-width:568px)" type="image/webp" srcset="/fbm-data/images/layout/banner/banner-interior-m.webp"/>
                            <source type="image/webp" srcset="/fbm-data/images/layout/banner/banner-interior.webp"/>
                                <img src="/fbm-data/images/layout/banner/banner-interior.jpg" class="bg-picture-center" alt="remodeling company"/>
                    </picture>
                </div>
            </div>
            <div id="page">
                <main class="container-fluid" id="content">
                    <div class="row bg-white">
                        <div class="col-xl-8 col-lg-10 col-12 mx-auto p-xl-5 py-5">
                            <div class="p-0 p-lg-5">
                                <div id="cms-breadcrumb" class="minimal-breadcrumb d-lg-flex d-none">

                                    <div class="trail">
                                        <Link title="Home" to="/">Home</Link>
                                    </div>

                                    <div class="trail">
                                        <Link title="Siding" to="/siding">Siding</Link>
                                    </div>

                                    <div class="trail">
                                        <Link title="James Hardie Siding" to="/james-hardie-siding">James Hardie Siding</Link>
                                    </div>

                                </div>
                                <h1 class="">James Hardie Siding In Bergen County</h1>
                                <picture>
                                    <source type="image/webp" srcset="/fbm-data/images/siding/james-hardie-siding.webp"/>
                                        <img alt="James hardie siding" src="/fbm-data/images/siding/james-hardie-siding.jpg" class="img-r-dynamic"/>
                                </picture>
                                <p>
                                    If you've been dreaming of updating the look of your Paramus home, Aspen Home Remodeling offers James Hardie Siding in a wide array of design and color options to make your home remodeling dreams come true!
                                </p>
                                <p>
                                    New James Hardie siding can enhance your curb appeal and elevate the value of your property. This budget-friendly option gives your home a designer look, but it also saves you money with low-maintenance and lower energy costs. With added benefits like noise reduction and easy cleaning, you'll love the difference that James Hardie siding can make for your Paramus home.
                                </p>
                                <p>
                                    James Hardie siding is the recognized industry leader in fiber cement siding products. James Hardie siding products are made in the USA and are covered by a full manufacturer's warranty for your peace of mind. James Hardie siding is available in a wide range of color and design options and is the perfect balance of cost savings and lifetime value to give you the Paramus home of your dreams.
                                </p>
                                <h2 class=""> Top-Rated Siding Contractor, Serving Paramus, Oradell, And The Surrounding Communities </h2>
                                <p>
                                    For over a quarter of a century, the home remodeling professionals at Aspen Home Remodeling have been serving our friends and neighbors in Oradell, Paramus, and the surrounding communities with quality workmanship and exceptional results.
                                </p>
                                <p>
                                    Home remodeling is in our DNA, and we enjoy sharing our talent with our community. We don't just want to be the <Link to="/">siding contractor in Paramus</Link> that takes care of your home, we want to be the siding contractor you refer to your friends, your neighbors, and your relatives!
                                </p>
                                <p>
                                    We're proud to offer:
                                </p>
                                <ul class="">
                                    <li class="">25+ Years of Experience</li>
                                    <li class="">USA-Made Materials</li>
                                    <li class="">Energy Star®-Approved Products</li>
                                    <li class="">Limited Lifetime Warranties</li>
                                    <li class="">Easy Financing Options</li>
                                    <li class="">FREE Virtual Estimates</li>
                                </ul>
                                <p>
                                    Aspen Home Remodeling offers a vast array of James Hardie siding and <Link to="/vinyl-siding">vinyl siding</Link> options to enhance the look of your Paramus area home. Whether you're a first-time homeowner hoping to update your fixer-upper or an established homeowner who is finally ready to make your home the home of your dreams, we're here to offer you excellent response times, exceptionally clean job sites, and a top-notch crew of home remodeling professionals who will treat your home like we'd treat our own.
                                </p>
                                <h2 class="">Financing Solutions To Meet Your Home Remodeling Budget</h2>
                                <p>
                                    If you're on the fence about whether you can afford to work with a remodeler, Aspen Home Remodeling is here to help. With our free in-person and virtual estimates, transparent up-front pricing, and affordable finance options, we can help you make your home remodeling dreams a comfortable reality!
                                </p>
                                <h2 class=""> American-Made Products And Hometown Professionals </h2>
                                <p>
                                    Supporting local businesses and buying American-made products never goes out of style for our hard-working friends and neighbors in the northeastern New Jersey area. We appreciate the support of our repeat and referral customers, and we will always treat your home with the same care and concern we'd treat our own.
                                </p>
                                <p>
                                    All of the products we use for our home remodeling projects, from vinyl windows to roofing materials to James Hardie siding are made in the USA. We're committed to using top-quality materials that have been verified by the Vinyl Siding Institute and Energy Star®-Approved Products that meet the highest industry standards.
                                </p>
                                <h2>Latest James Hardie Siding Projects</h2>
                                <div class="row mx-auto px-2 py-0">

                                    <div class="col-xl-4 col-md-6 col-12 mx-auto p-1">
                                        <div class="card h-100 project-cards">
                                            <picture>
                                                <img src="/fbm-data/images/projects/roofing-siding-new-entry-door-new-milford-nj-after.webp" alt="Roofing, Siding, & New Entry Door Project in New Milford, NJ" class="card-img-top"/>
                                            </picture>
                                            <div class="card-body">
                                                <div class="project-title">
                                                    <Link to="/projects/roofing-siding-new-entry-door-project-in-new-milford-nj">Roofing, Siding, & New Entry Door Project in New Milford, NJ</Link>
                                                </div>
                                                <p class="my-2">Aspen Home Remodeling recently completed a project of roof replacement, aluminum siding removal with new vinyl siding, and a front entry door replacement.

                                                    Roof Replacement
                                                    Vinyl Siding
                                                    Entry D [&hellip;]</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>


                    <FooterUpper/>
                </main>
            </div>
</div>
    );
}

export default JamesHardieSiding;