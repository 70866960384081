import React from "react";
import {FooterUpper} from "./index";
function BeforAndAfterGallery() {
    return(
        <div>
            <div id="banner">
                <div class="bg-picture-container">
                    <picture>
                        <source media="(max-width:568px)" type="image/webp" srcset="/fbm-data/images/layout/banner/banner-interior-m.webp"/>
                            <source type="image/webp" srcset="/fbm-data/images/layout/banner/banner-interior.webp"/>
                                <img src="/fbm-data/images/layout/banner/banner-interior.jpg" class="bg-picture-center" alt="remodeling company"/>
                    </picture>
                </div>
            </div>
            <div id="page">
                <main class="container-fluid" id="content">
                    <div class="row bg-white">
                        <div class="col-xl-8 col-lg-10 col-12 mx-auto p-xl-5 py-5">
                            <div class="p-0 p-lg-5">
                                <div id="cms-breadcrumb" class="minimal-breadcrumb d-lg-flex d-none">
                                    <div class="trail">
                                        <a title="Home" href="/">Home</a>
                                    </div>

                                    <div class="trail">
                                        <a title="Before and After Gallery" href="/before-and-after-gallery">Before and After Gallery</a>
                                    </div>

                                </div>
                                <h1 class="">Our Before and After Gallery</h1>

                                <div class="gallery">

                                    <a href="/fbm-data/images/before and after gallery/before-vinyl-siding-1.webp" data-lightbox="Before and After Gallery">
                                        <img alt="Before vinyl siding 1" src="/fbm-data/images/before and after gallery/thumbs/before-vinyl-siding-1.webp"/>
                                    </a>

                                    <a href="/fbm-data/images/before and after gallery/after-vinyl-siding-1.webp" data-lightbox="Before and After Gallery">
                                        <img alt="After vinyl siding 1" src="/fbm-data/images/before and after gallery/thumbs/after-vinyl-siding-1.webp"/>
                                    </a>

                                    <a href="/fbm-data/images/before and after gallery/before-entry-doors-1.webp" data-lightbox="Before and After Gallery">
                                        <img alt="Before entry doors 1" src="/fbm-data/images/before and after gallery/thumbs/before-entry-doors-1.webp"/>
                                    </a>

                                    <a href="/fbm-data/images/before and after gallery/after-entry-doors-1.webp" data-lightbox="Before and After Gallery">
                                        <img alt="After entry doors 1" src="/fbm-data/images/before and after gallery/thumbs/after-entry-doors-1.webp"/>
                                    </a>

                                    <a href="/fbm-data/images/before and after gallery/before-siding-roofing.webp" data-lightbox="Before and After Gallery">
                                        <img alt="Before siding roofing" src="/fbm-data/images/before and after gallery/thumbs/before-siding-roofing.webp"/>
                                    </a>

                                    <a href="/fbm-data/images/before and after gallery/after-siding-roofing.webp" data-lightbox="Before and After Gallery">
                                        <img alt="After siding roofing" src="/fbm-data/images/before and after gallery/thumbs/after-siding-roofing.webp"/>
                                    </a>

                                    <a href="/fbm-data/images/before and after gallery/before-home-renovation.webp" data-lightbox="Before and After Gallery">
                                        <img alt="Before home renovation" src="/fbm-data/images/before and after gallery/thumbs/before-home-renovation.webp"/>
                                    </a>

                                    <a href="/fbm-data/images/before and after gallery/after-home-renovation.webp" data-lightbox="Before and After Gallery">
                                        <img alt="After home renovation" src="/fbm-data/images/before and after gallery/thumbs/after-home-renovation.webp"/>
                                    </a>

                                    <a href="/fbm-data/images/before and after gallery/before-roofing.webp" data-lightbox="Before and After Gallery">
                                        <img alt="Before roofing" src="/fbm-data/images/before and after gallery/thumbs/before-roofing.webp"/>
                                    </a>

                                    <a href="/fbm-data/images/before and after gallery/after-roofing.webp" data-lightbox="Before and After Gallery">
                                        <img alt="After roofing" src="/fbm-data/images/before and after gallery/thumbs/after-roofing.webp"/>
                                    </a>

                                    <a href="/fbm-data/images/before and after gallery/before-roofing-1.webp" data-lightbox="Before and After Gallery">
                                        <img alt="Before roofing 1" src="/fbm-data/images/before and after gallery/thumbs/before-roofing-1.webp"/>
                                    </a>

                                    <a href="/fbm-data/images/before and after gallery/after-roofing-1.webp" data-lightbox="Before and After Gallery">
                                        <img alt="After roofing 1" src="/fbm-data/images/before and after gallery/thumbs/after-roofing-1.webp"/>
                                    </a>

                                    <a href="/fbm-data/images/before and after gallery/before-siding.webp" data-lightbox="Before and After Gallery">
                                        <img alt="Before siding" src="/fbm-data/images/before and after gallery/thumbs/before-siding.webp"/>
                                    </a>

                                    <a href="/fbm-data/images/before and after gallery/after-siding.webp" data-lightbox="Before and After Gallery">
                                        <img alt="After siding" src="/fbm-data/images/before and after gallery/thumbs/after-siding.webp"/>
                                    </a>

                                    <a href="/fbm-data/images/before and after gallery/before-after-home-renovation.webp" data-lightbox="Before and After Gallery">
                                        <img alt="Before after home renovation" src="/fbm-data/images/before and after gallery/thumbs/before-after-home-renovation.webp"/>
                                    </a>

                                    <a href="/fbm-data/images/before and after gallery/before-after-home-renovation-2.webp" data-lightbox="Before and After Gallery">
                                        <img alt="Before after home renovation 2" src="/fbm-data/images/before and after gallery/thumbs/before-after-home-renovation-2.webp"/>
                                    </a>

                                    <a href="/fbm-data/images/before and after gallery/before-after-siding.webp" data-lightbox="Before and After Gallery">
                                        <img alt="Before after siding" src="/fbm-data/images/before and after gallery/thumbs/before-after-siding.webp"/>
                                    </a>

                                </div>						</div>
                        </div>
                    </div>


                    <FooterUpper/>
                </main>
            </div>
        </div>
    );
}
export default BeforAndAfterGallery;