import React from "react";
import {ContactForm, FooterUpper} from "./index";
function Contact() {
    return(
        <div>
            <div id="banner">
                <div class="bg-picture-container">
                    <picture>
                        <source media="(max-width:568px)" type="image/webp" srcset="/fbm-data/images/layout/banner/banner-interior-m.webp"/>
                            <source type="image/webp" srcset="/fbm-data/images/layout/banner/banner-interior.webp"/>
                                <img src="/fbm-data/images/layout/banner/banner-interior.jpg" class="bg-picture-center" alt="remodeling company"/>
                    </picture>
                </div>
            </div>
            <div id="page">
                <main class="container-fluid" id="content">
                    <div class="row bg-white">
                        <div class="col-xl-8 col-lg-10 col-12 mx-auto p-xl-5 py-5">
                            <div class="p-0 p-lg-5">
                                <div id="cms-breadcrumb" class="minimal-breadcrumb d-lg-flex d-none">
                                    <div class="trail">
                                        <a title="Home" href="/">Home</a>
                                    </div>

                                    <div class="trail">
                                        <a title="Contact Us" href="/contact-us">Contact Us</a>
                                    </div>

                                </div>
                                <h1>Contact Aspen Home Remodeling</h1>
                                <h2>Get a FREE Consultation Today!</h2>
                                <div class="row">
                                    <div class="col-12 col-xl-6">
                                        <div class="p-3 shadow" style={{backgroundColor:'#0a2a6b'}}>
                                            <ContactForm/>
                                        </div>
                                    </div>
                                    <div class="col-12 col-xl-6">
                                        <div class="row">
                                            <div class="col-12 col-xl-6">
                                                <div class="p-3 bg-white shadow h-100">
                                                    <h4 class="text-center">Call Us Today</h4>
                                                    <p class="text-center h4 pt-3">
                                                        <a class="text-primary" href="tel:+12019945354"><i class="fas fa-phone"></i> 201-994-5354</a>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-12 col-xl-6 pt-3 pt-xl-0">
                                                <div class="p-3 bg-white shadow h-100">
                                                    <h4 class="text-center"><i class="fas fa-warehouse"></i> Contact Us</h4>
                                                    <p class="text-center">
                                                        Aspen Home Remodeling<br/>
                                                        700 Kinderkamack Rd. #101<br/>
                                                        Oradell, NJ 07649
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 pt-4">
                                                <div class="bg-white shadow">
                                                    <div class="px-3 pt-3">
                                                        <h4><i class="fas fa-map-marker-alt"></i> Find Us!</h4>
                                                    </div>
                                                    <div class="embed-responsive embed-responsive-16by9">
                                                        <iframe title="Contact" class="embed-responsive-item" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3012.7012975099533!2d-74.03174168383046!3d40.96612202996537!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2fbf57e6b2941%3A0x5d27f71ef2dcb496!2sAspen%20Home%20Remodeling%2C%20LLC.!5e0!3m2!1sen!2sus!4v1616002338327!5m2!1sen!2sus"></iframe>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <FooterUpper/>
                </main>
            </div>
        </div>
    );
}
export default Contact;