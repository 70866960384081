import React from "react";
import {Link, withRouter} from "react-router-dom";
import {FooterUpper} from "./index";

class ParamusRoofingContractor extends React.Component{
    render() {
        return (
            <div>
                <div id="banner">
                    <div className="bg-picture-container">
                        <picture>
                            <source media="(max-width:568px)" type="image/webp"
                                    srcSet="/fbm-data/images/layout/banner/banner-interior-m.webp"/>
                                <source type="image/webp" srcSet="/fbm-data/images/layout/banner/banner-interior.webp"/>
                                    <img src="/fbm-data/images/layout/banner/banner-interior.jpg"
                                         className="bg-picture-center" alt="remodeling company"/>
                        </picture>
                    </div>
                </div>
                <div id="page">
                    <div className="container-fluid my-5">
                        <div className="row">
                            <div className="col-12 col-xl-8 mx-auto">
                                <div id="cms-breadcrumb" className="minimal-breadcrumb d-lg-flex d-none">
                                    <div className="trail">
                                        <a title="Home" href="/">Home</a>
                                    </div>

                                    <div className="trail">
                                        <Link title="Service Areas" to="/near-me">Service Areas</Link>
                                    </div>

                                    <div className="trail">
                                        <Link title="Paramus, NJ" to="/near-me/paramus-roofing-contractor">Paramus,
                                            NJ</Link>
                                    </div>

                                </div>
                                <div id="content">
                                    <h1 className="">Your Local Roofing Contractor Company in Paramus, NJ</h1>
                                    <picture>
                                        <source type="image/webp"
                                                srcSet="/fbm-data/images/cities/paramus-replacement-windows.webp"/>
                                            <img alt="Paramus replacement windows"
                                                 src="/fbm-data/images/cities/paramus-replacement-windows.jpg"
                                                 className="img-r-dynamic"/>
                                    </picture>
                                    <p>
                                        <strong>We don't want to just be the Paramus roofing contractor you call, we
                                            want to be the roofing contractor you tell your friends, neighbors, and
                                            relatives to call, too!</strong>
                                    </p>
                                    <p>
                                        At Aspen Home Remodeling, we understand what your Paramus area home means to
                                        you. We're your local hometown roofing contractor, with over 25 years of
                                        experience in the home remodeling industry. We're committed to treating your
                                        home with the same skill and care that we would treat our own.
                                    </p>
                                    <p>
                                        From minor roof repairs to emergency service to complete roof replacement, our
                                        skilled professionals are here to provide you with quality workmanship and
                                        beautiful results. We pride ourselves on our reputation - and good news travels
                                        fast!
                                    </p>
                                    <h2 className=""> American-Made Products Offer Variety &amp; Style </h2>
                                    <p>
                                        Now more than ever, we're all aware of the importance of supporting local
                                        businesses and buying American-made products. We're the area's leading local <a
                                        href="/">roofing contractor in Paramus</a>, serving an ever-growing number of
                                        satisfied customers, friends, and neighbors in northeastern New Jersey in
                                        communities like Mahwah, Emerson, and Hackensack. We work with some of the best
                                        manufacturers in the roofing industry to deliver durable American-made roofing
                                        materials that deliver long-lasting results for our clients.
                                    </p>
                                    <p>
                                        We have a variety of design, style, and color choices to make your roofing
                                        vision a reality. Whether you're looking to balance style and budget with basic
                                        3-tab singles, love the high-end look of designer dimensional shingles, or
                                        prefer the classic style and durability of metal roofing, Aspen Home Remodeling
                                        offers a variety of roofing types to fit your needs.
                                    </p>
                                    <h2 className=""> Your Paramus Roofing Contractor, Also Offering Replacement Windows
                                        And More! </h2>
                                    <p>
                                        We're not only the area's leading roofing contractor, but we also offer a full
                                        line of exterior home remodeling services for your Paramus home. Whether you
                                        need replacement windows, <Link to="/entry-doors">entry doors</Link>, or new siding
                                        for your Paramus area home, we can guide you through the process from start to
                                        finish to make your home remodeling vision a reality.
                                    </p>
                                    <p>
                                        All of the products we use for our home remodeling projects, from roofing
                                        materials to windows to vinyl siding are proudly made in the USA. Using
                                        materials that are verified by the Vinyl Siding Institute and Energy
                                        Star®-Approved Products means that we're committed to providing top-quality home
                                        remodeling products that are installed by a team of trained professionals. Home
                                        remodeling isn't just something we do, it's something we enjoy - some would say
                                        it is in our DNA!
                                    </p>
                                    <p>
                                        Our home remodeling services include:
                                    </p>
                                    <ul className="">
                                        <li className="">Roofing</li>
                                        <li className="">James Hardie and Vinyl Siding</li>
                                        <li className="">Windows</li>
                                        <li className="">Entry doors</li>
                                        <li className="">Gutters &amp; trim</li>
                                        <li className="">Shutters</li>
                                    </ul>

                                    <p>
                                        Aspen Home Remodeling is proud to be the remodeling and roofing contractor our
                                        friends and neighbors in Paramus and the surrounding Bergen County communities
                                        call for all of their home remodeling needs. Our customers rave about our
                                        follow-up during and after the job, our quick response times, the cleanliness of
                                        our worksites, and our skilled and courteous staff.
                                    </p>
                                    <p>
                                        Aspen Home Services offers affordable financing options, free in-person and
                                        virtual estimates, and quick turnaround times on our home remodeling projects.
                                        Call the home remodeler your friends and neighbors in Paramus trust for all of
                                        their exterior home improvement needs and let us make your home remodeling
                                        dreams come true!
                                    </p>
                                    <h2 className="">Remodeling Solutions For Any Budget</h2>
                                    <p>
                                        At Aspen Home Remodeling, we offer free in-person and virtual estimates and
                                        honest, up-front pricing on every home remodeling project. With unbeatable
                                        down-the-line prices and affordable finance options to meet any budget, our
                                        remodeling professionals will make your home remodeling dreams a comfortable
                                        reality.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-xl-8 mx-auto">
                                <div className="text-center">
                                    <div className="my-3">
                                        <a rel="noreferrer" className="btn btn-more" target="_blank"
                                           href="https://www.paramusborough.org/">Paramus, New Jersey</a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-6 mx-auto">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe title="map" className="embed-responsive-item"
                                                    src="//maps.google.com/?q=Paramus+New+Jersey&output=embed"></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="my-5">
                                    <div className="testimonials-container">
                                        <div className="row">
                                            <div className="col-12"><h2>Reviews & Testimonials on Aspen Home Remodeling
                                                in Paramus, NJ</h2></div>
                                            <div className="w-100"></div>
                                            <div className="col-12"><p>
                                                There are currently no reviews or testimonials for <em>Paramus, NJ</em>.
                                                Check back soon!
                                            </p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <FooterUpper/>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ParamusRoofingContractor);
