import React from "react";
import {Link} from "react-router-dom";
import {FooterUpper} from "./index";

function Reviews() {
    return (
        <div>
            <div id="banner">
                <div class="bg-picture-container">
                    <picture>
                        <source media="(max-width:568px)" type="image/webp"
                                srcset="/fbm-data/images/layout/banner/banner-interior-m.webp"/>
                        <source type="image/webp" srcset="/fbm-data/images/layout/banner/banner-interior.webp"/>
                        <img src="/fbm-data/images/layout/banner/banner-interior.jpg" class="bg-picture-center"
                             alt="remodeling company"/>
                    </picture>
                </div>
            </div>
            <div id="page">
                <main class="container-fluid" id="content">
                    <div class="row bg-white">
                        <div class="col-xl-8 col-lg-10 col-12 mx-auto p-xl-5 py-5">
                            <div class="p-0 p-lg-5">
                                <div id="cms-breadcrumb" class="minimal-breadcrumb d-lg-flex d-none">
                                    <div class="trail">
                                        <Link title="Home" to="/">Home</Link>
                                    </div>

                                    <div class="trail">
                                        <Link title="Reviews" to="/reviews">Reviews</Link>
                                    </div>

                                </div>
                                <h1 class="">Read Testimonials and Reviews For Aspen Home Remodeling</h1>
                                <div class="testimonials-container">
                                    <div class="row">

                                        <div class="col-lg-6 pb-4">
                                            <div
                                                class="bg-white border-left-primary p-4 mb-3 shadow h-100 d-flex flex-column">
                                                <p>Russ and his team could not have made our roofing and siding
                                                    replacement a more smoother process. They were attentive, efficient,
                                                    and professional from start to finish. Their pricing was more than
                                                    competitive and more importantly, they made the roofing replacement
                                                    process easy. We highly recommend Russ and his awesome team you
                                                    won’t regret it! Thank you for making our home, our dream home.</p>
                                                <p class="mt-auto">
                                                    - <i class="fas fa-comment"></i> <span>Gayle C.</span> | <span>River Edge, NJ</span> | <svg
                                                    fill="gold" width="1em" height="1em" viewBox="0 0 16 16"
                                                    class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                </svg>
                                                    <svg fill="gold" width="1em" height="1em" viewBox="0 0 16 16"
                                                         class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                    </svg>
                                                    <svg fill="gold" width="1em" height="1em" viewBox="0 0 16 16"
                                                         class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                    </svg>
                                                    <svg fill="gold" width="1em" height="1em" viewBox="0 0 16 16"
                                                         class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                    </svg>
                                                    <svg fill="gold" width="1em" height="1em" viewBox="0 0 16 16"
                                                         class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                    </svg>
                                                </p>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 pb-4">
                                            <div
                                                class="bg-white border-left-primary p-4 mb-3 shadow h-100 d-flex flex-column">
                                                <p>Aspen did a wonderful job on replacing our roof. Everything is well
                                                    organized, clear contract, and clean work after completed. They do
                                                    know the value of customer service and satisfaction. We highly
                                                    recommend Russ and Aspen company for any future home remodeling
                                                    projects you may have. Thank you for making our home very lovely and
                                                    giving us a peace of mind regarding the work quality.</p>
                                                <p class="mt-auto">
                                                    - <i class="fas fa-comment"></i> <span>Popoy  J.</span> | <span>River Edge, NJ</span> | <svg
                                                    fill="gold" width="1em" height="1em" viewBox="0 0 16 16"
                                                    class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                </svg>
                                                    <svg fill="gold" width="1em" height="1em" viewBox="0 0 16 16"
                                                         class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                    </svg>
                                                    <svg fill="gold" width="1em" height="1em" viewBox="0 0 16 16"
                                                         class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                    </svg>
                                                    <svg fill="gold" width="1em" height="1em" viewBox="0 0 16 16"
                                                         class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                    </svg>
                                                    <svg fill="gold" width="1em" height="1em" viewBox="0 0 16 16"
                                                         class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                    </svg>
                                                </p>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 pb-4">
                                            <div
                                                class="bg-white border-left-primary p-4 mb-3 shadow h-100 d-flex flex-column">
                                                <p>Russell and his team at Aspen did a phenomenal job and were true
                                                    professionals. Russell provided honest feedback and provided us with
                                                    as many choices as possible for our siding & gutter replacement
                                                    project. They completed the job way ahead of schedule and exceeded
                                                    our expectations. We'll be hiring Aspen again for our future home
                                                    improvement projects, and I'd highly recommend their services.</p>
                                                <p class="mt-auto">
                                                    - <i class="fas fa-comment"></i>
                                                    <span>Christopher I.</span> | <span>River Edge, NJ</span> | <svg
                                                    fill="gold" width="1em" height="1em" viewBox="0 0 16 16"
                                                    class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                </svg>
                                                    <svg fill="gold" width="1em" height="1em" viewBox="0 0 16 16"
                                                         class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                    </svg>
                                                    <svg fill="gold" width="1em" height="1em" viewBox="0 0 16 16"
                                                         class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                    </svg>
                                                    <svg fill="gold" width="1em" height="1em" viewBox="0 0 16 16"
                                                         class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                    </svg>
                                                    <svg fill="gold" width="1em" height="1em" viewBox="0 0 16 16"
                                                         class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                    </svg>


                                                </p>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 pb-4">
                                            <div
                                                class="bg-white border-left-primary p-4 mb-3 shadow h-100 d-flex flex-column">
                                                <p>We recently replaced our siding and gutters with Aspen. We met with
                                                    three siding companies and so glad we went with Aspen. One thing
                                                    that stands out from others is that he offers far better
                                                    quality/construction trim around the windows and doors. Russ, the
                                                    owner, is very responsive and professional. He knows what he is
                                                    doing and is extremely dedicated to his work. We now have a
                                                    beautiful blue house! We will be getting more work done around the
                                                    house with his company for sure.</p>
                                                <p class="mt-auto">
                                                    - <i class="fas fa-comment"></i> <span>Christine B.</span> | <span>River Edge, NJ</span> | <svg
                                                    fill="gold" width="1em" height="1em" viewBox="0 0 16 16"
                                                    class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                </svg>
                                                    <svg fill="gold" width="1em" height="1em" viewBox="0 0 16 16"
                                                         class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                    </svg>
                                                    <svg fill="gold" width="1em" height="1em" viewBox="0 0 16 16"
                                                         class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                    </svg>
                                                    <svg fill="gold" width="1em" height="1em" viewBox="0 0 16 16"
                                                         class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                    </svg>
                                                    <svg fill="gold" width="1em" height="1em" viewBox="0 0 16 16"
                                                         class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                    </svg>
                                                </p>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 pb-4">
                                            <div
                                                class="bg-white border-left-primary p-4 mb-3 shadow h-100 d-flex flex-column">
                                                <p>Russel is very professional and his customer service is top-notch.
                                                    This is the first time I’m writing a review for any business because
                                                    I’ve been very impressed so far. Work hasn’t yet been completed but
                                                    he has been phenomenal up to this point. It’s very difficult to find
                                                    such businesses these days. If you're debating on using him, don’t
                                                    hesitate he’s the best! We will be doing more projects with him.</p>
                                                <p class="mt-auto">
                                                    - <i class="fas fa-comment"></i> <span>Karon A.</span> | <span>River Edge, NJ</span> | <svg
                                                    fill="gold" width="1em" height="1em" viewBox="0 0 16 16"
                                                    class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                </svg>
                                                    <svg fill="gold" width="1em" height="1em" viewBox="0 0 16 16"
                                                         class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                    </svg>
                                                    <svg fill="gold" width="1em" height="1em" viewBox="0 0 16 16"
                                                         class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                    </svg>
                                                    <svg fill="gold" width="1em" height="1em" viewBox="0 0 16 16"
                                                         class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                    </svg>
                                                    <svg fill="gold" width="1em" height="1em" viewBox="0 0 16 16"
                                                         class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                    </svg>
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <FooterUpper/>
                </main>
            </div>
        </div>
    );
}

export default Reviews;