import React from "react";
import {Link} from "react-router-dom";

function Footer() {
    return (
        <footer className="fb-ftr-1">
            <div className="container-fluid mx-auto">
                <div className="row big-wrap mx-auto px-3 px-md-5 pt-3 pt-md-5 pb-1 pb-md-2">
                    <div className="col-lg-3 col-sm-6 col-12 align-self-center text-center mx-auto p-3">
                        <a href="/">
                            <div className="d-block d-lg-none">
                                <picture>
                                    <source media="(max-width:568px)" type="image/webp"
                                            srcSet="/fbm-data/images/layout/logo-m.webp"/>
                                        <source type="image/webp" srcSet="/fbm-data/images/layout/logo.webp"/>
                                            <img src="/fbm-data/images/layout/logo.png" alt="Aspen Home Remodeling logo"
                                                 width="350"/>
                                </picture>
                            </div>
                            <div className="d-none d-lg-block">
                                <picture>
                                    <source media="(max-width:568px)" type="image/webp"
                                            srcSet="/fbm-data/images/layout/logo-full-m.webp"/>
                                        <source type="image/webp" srcSet="/fbm-data/images/layout/logo-full.webp"/>
                                            <img src="/fbm-data/images/layout/logo-full.png"
                                                 alt="Aspen Home Remodeling logo" width="350"/>
                                </picture>
                            </div>
                        </a>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12 text-center text-md-left mx-auto p-3">
                        <h4>Contact</h4>
                        <div className="contact">
                            <p itemProp="legalName">
                                Aspen Home Remodeling </p>
                            <div itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
                                <p itemProp="streetAddress">
                                    700 Kinderkamack Rd. #101 </p>
                                <p>
                                    <span itemProp="addressLocality">Oradell</span>,
                                    <span itemProp="addressRegion">NJ</span>
                                    <span itemProp="postalCode">07649</span>
                                </p>
                            </div>
                            <p className="mt-3 mb-0">
                                Phone: <a href="tel:+1-2019945354" itemProp="telephone"
                                          content="201-994-5354">201-994-5354</a>
                            </p>
                            <p className="mt-3 mb-0">
                                <i className="sml fab fa-cc-mastercard mr-1"></i>
                                <i className="sml fab fa-cc-visa"></i>
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12 text-center text-md-left mx-auto p-3">
                        <h4>Quick Links</h4>
                        <div className="quick-links">
                            <a href="https://g.page/aspen-home-remodeling-llc-?share" target="_blank" rel="noreferrer">Google
                                Places</a>
                            <a href="https://www.google.com/search?q=Aspen+Home+Remodeling+700+Kinderkamack+Rd.+%23101+Oradell%2C+NJ+07649+Phone%3A+201-994-5354&rlz=1C1CHBF_enUS775US775&oq=Aspen+Home+Remodeling+700+Kinderkamack+Rd.+%23101+Oradell%2C+NJ+07649+Phone%3A+201-994-5354&aqs=chrome..69i57.415j0j7&sourceid=chrome&ie=UTF-8#lrd=0x89c2fbf57e6b2941:0x5d27f71ef2dcb496,3,,,"
                               target="_blank" rel="noreferrer">Write Review</a>
                            <Link to="/roofing-contractor-tips">Articles</Link>
                            <Link to="/privacy">Privacy Policy</Link>
                            <Link to="/terms">Terms of Use</Link>
                            <Link to="/sitemap">Sitemap</Link>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12 text-center text-md-left mx-auto p-3">
                        <h4>Service Areas</h4>
                        <div className="service-areas">
                            <Link to="/near-me/river-edge-roofing-contractor">River Edge, NJ</Link>
                            <Link to="/near-me/oradell-roofing-contractor">Oradell, NJ</Link>
                            <Link to="/near-me/paramus-roofing-contractor">Paramus, NJ</Link>
                            <Link to="/near-me/ridgewood-roofing-contractor">Ridgewood, NJ</Link>
                            <Link to="/near-me/wyckoff-roofing-contractor">Wyckoff, NJ</Link>
                            <Link to="/near-me/roofing-contractor-glen-rock">Glen Rock, NJ</Link>
                            <Link to="/near-me/roofing-contractor-emerson">Emerson, NJ</Link>
                            <Link to="/near-me/roofing-contractor-hackensack">Hackensack, NJ</Link>
                            <Link to="/near-me/roofing-contractor-mahwah">Mahwah, NJ</Link>
                            <Link to="/near-me/roofing-contractor-ramsey">Ramsey, NJ</Link>
                        </div>
                        <div className="mt-3 text-right">
                            <Link to="/near-me"><i className="fas fa-angle-right"></i> View All</Link>
                        </div>
                    </div>
                </div>
                <div className="row big-wrap mx-auto px-3 px-md-5 pt-1 pt-md-2 pb-3 pb-md-5">
                    <div className="col-12 text-center text-md-left mx-auto p-3"
                         style={{borderTop:'1px solid rgba(0, 0, 0, 0.2)'}}>
                        <p className="mb-0">
                            &copy;
                            2021 <span itemProp="name"><a itemProp="url" href="/">Aspen Home Remodeling</a></span>,
                            Rights Reserved
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;