import React from "react";
import {Link, withRouter} from "react-router-dom";
import {FooterUpper} from "./index";

class RoofingContractorGlenRock extends React.Component {
    render() {
        return (
            <div>
                <div id="banner">
                    <div className="bg-picture-container">
                        <picture>
                            <source media="(max-width:568px)" type="image/webp"
                                    srcSet="/fbm-data/images/layout/banner/banner-interior-m.webp"/>
                            <source type="image/webp" srcSet="/fbm-data/images/layout/banner/banner-interior.webp"/>
                            <img src="/fbm-data/images/layout/banner/banner-interior.jpg"
                                 className="bg-picture-center" alt="remodeling company"/>
                        </picture>
                    </div>
                </div>
                <div id="page">
                    <div className="container-fluid my-5">
                        <div className="row">
                            <div className="col-12 col-xl-8 mx-auto">
                                <div id="cms-breadcrumb" className="minimal-breadcrumb d-lg-flex d-none">
                                    <div className="trail">
                                        <Link title="Home" to="/">Home</Link>
                                    </div>

                                    <div className="trail">
                                        <Link title="Service Areas" to="/near-me">Service Areas</Link>
                                    </div>

                                    <div className="trail">
                                        <Link title="Glen Rock, NJ" to="/near-me/roofing-contractor-glen-rock">Glen
                                            Rock,
                                            NJ</Link>
                                    </div>

                                </div>
                                <div id="content">
                                    <h1 className="">Aspen Home Remodeling: Glen Rock's Premier In Roofing Contractor
                                        Services</h1>
                                    <picture>
                                        <source type="image/webp"
                                                srcSet="/fbm-data/images/cities/glen-rock-replacement-windows.webp"/>
                                        <img alt="Glen rock replacement windows"
                                             src="/fbm-data/images/cities/glen-rock-replacement-windows.jpg"
                                             className="img-r-dynamic"/>
                                    </picture>
                                    <p>
                                        <strong>Call the roofing contractor your friends and neighbors in Glen Rock and
                                            the surrounding communities call to enhance your curb appeal, lower your
                                            heating costs, and elevate the value of your property.</strong>
                                    </p>
                                    <p>
                                        A new roof can transform your home into the home of your dreams. At Aspen Home
                                        Remodeling, we're the roofing contractor who can help make those dreams a
                                        reality. We serve an ever-growing number of satisfied customers throughout Glen
                                        Rock and the northeastern New Jersey region and we'd love to add you to the
                                        list.
                                    </p>
                                    <p>
                                        We work with some of the top-rated roofing manufacturers in the industry to
                                        deliver durable roofing materials that are made in the USA by industry leaders
                                        who stand behind their products. Aspen Home Remodeling knows what your home
                                        means to you, and you can count on us to always provide you with top-quality
                                        products, professional workmanship, and relationship-based customer service that
                                        is second-to-none.
                                    </p>
                                    <p>
                                        We're proud to offer:
                                    </p>
                                    <ul className="">
                                        <li className="">25+ Years of Experience</li>
                                        <li className="">USA-Made Materials</li>
                                        <li className="">Energy Star®-Approved Products</li>
                                        <li className="">Limited Lifetime Warranties</li>
                                        <li className="">Easy Financing Options</li>
                                        <li className="">FREE Virtual Estimates</li>
                                    </ul>
                                    <h2 className="">Leave Roof Repair And Replacement To Your Professional Glen Rock
                                        Roofing Contractor</h2>
                                    <p>
                                        For most people, roofing is over their heads. That's why it's always best to
                                        leave roof repairs and roof replacement to a professional roofing contractor.
                                        Whether you need emergency roof repairs or complete roof replacement for your
                                        Glen Rock home, Aspen Home Remodeling has got you covered. We offer quick
                                        response times, hands-on emergency services, and follow-ups before, during, and
                                        after your project to ensure that your vision becomes a reality.
                                    </p>
                                    <p>
                                        At Aspen Home Remodeling, all of our roofing products are made in the USA by
                                        industry leaders you know and trust. With our expert guidance, we'll help you
                                        find the right balance of style and budget. We offer a wide array of design and
                                        color choices, and easy financing options to give you the roof of your dreams!
                                    </p>
                                    <h2 className=""> Siding, Replacement Windows, Entry Doors &amp; More For Your Glen
                                        Rock Home </h2>
                                    <p>
                                        We know what your home means to you, and you can count on the hometown
                                        professionals at Aspen Home Remodeling to always treat your home with the same
                                        care and concern that we'd treat our own. We committed to providing you with
                                        quality workmanship, professional results, and increased energy efficiency and
                                        offer a complete line of professional remodeling services, including:
                                    </p>
                                    <ul className="">
                                        <li className="">Roofing</li>
                                        <li className="">Vinyl &amp; <Link to="/james-hardie-siding">James Hardie
                                            Siding</Link></li>
                                        <li className="">Windows</li>
                                        <li className="">Entry doors</li>
                                        <li className="">Gutters &amp; trim</li>
                                        <li className="">Shutters</li>
                                    </ul>

                                    <p>
                                        We live and work in your community, and our reputation speaks for itself - good
                                        news travels fast! We don't want to just be the remodeling and roofing
                                        contractor that you call for your home remodeling projects, we want to be the
                                        remodeling and <Link to="/">roofing contractor in Glen Rock</Link> that you tell
                                        your friends, neighbors, and relatives to call!
                                    </p>
                                    <p>
                                        Find out what a difference our exterior home remodeling services can make for
                                        your home in the Glen Rock area by calling us today at 201-994-5354 or filling
                                        out our easy online contact form. Aspen Home Remodeling is ready to serve you
                                        for all of your home remodeling needs!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-xl-8 mx-auto">
                                <div className="text-center">
                                    <div className="my-3">
                                        <a rel="noreferrer" className="btn btn-more" target="_blank" href="https://glenrocknj.net/">Glen
                                            Rock, New Jersey</a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-6 mx-auto">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe title="map" className="embed-responsive-item"
                                                    src="//maps.google.com/?q=Glen+Rock+New+Jersey&output=embed"></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="my-5">
                                    <div className="testimonials-container">
                                        <div className="row">
                                            <div className="col-12"><h2>Reviews & Testimonials on Aspen Home Remodeling
                                                in Glen Rock, NJ</h2></div>
                                            <div className="w-100"></div>
                                            <div className="col-12"><p>
                                                There are currently no reviews or testimonials for <em>Glen Rock,
                                                NJ</em>. Check back soon!
                                            </p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <FooterUpper/>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(RoofingContractorGlenRock);
