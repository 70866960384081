import React from "react";
import {Link} from "react-router-dom";
import {FooterUpper} from "./index";

function Roofing() {
    return (
        <div>
            <div id="banner">
                <div class="bg-picture-container">
                    <picture>
                        <source media="(max-width:568px)" type="image/webp" srcset="/fbm-data/images/layout/banner/banner-interior-m.webp"/>
                            <source type="image/webp" srcset="/fbm-data/images/layout/banner/banner-interior.webp"/>
                                <img src="/fbm-data/images/layout/banner/banner-interior.jpg" class="bg-picture-center" alt="remodeling company"/>
                    </picture>
                </div>
            </div>
            <div id="page">
                <main class="container-fluid" id="content">
                    <div class="row bg-white">
                        <div class="col-xl-8 col-lg-10 col-12 mx-auto p-xl-5 py-5">
                            <div class="p-0 p-lg-5">
                                <div id="cms-breadcrumb" class="minimal-breadcrumb d-lg-flex d-none">
                                    <div class="trail">
                                        <Link title="Home" to="/">Home</Link>
                                    </div>

                                    <div class="trail">
                                        <Link title="Roofing" to="/roofing">Roofing</Link>
                                    </div>

                                </div>
                                <h1 class="">Experienced Roofing In Bergen County</h1>
                                <p>
                                    <strong>New roofing can enhance the look of your River Edge home, lower your heating costs, and elevate the value of your property.</strong> To transform your home into the home of your dreams, call the roofing specialists your friends and neighbors trust for their roofing needs, Aspen Home Remodeling.
                                </p>
                                <p>
                                    We're the preferred <Link to="/">roofing contractor for River Edge</Link>, and we serve an ever-growing number of satisfied customers in Northeast New Jersey. We work with some of the top-rated roofing manufacturers in the industry to deliver durable roofing materials that are made in the USA by industry leaders who stand behind their products.
                                </p>
                                <p>
                                    We know what your home means to you, and at Aspen Home Remodeling, we will always treat your home with the same care and concern that we'd treat our own. We don't just want to be the roofing contractor that takes care of your home, we want to be the roofing contractor you refer to your friends, your neighbors, and your relatives!
                                </p>
                                <p>
                                    We're proud to offer:
                                </p>
                                <ul class="">
                                    <li class="">25+ Years of Experience</li>
                                    <li class="">USA-Made Materials</li>
                                    <li class="">Energy Star®-Approved Products</li>
                                    <li class="">Limited Lifetime Warranties</li>
                                    <li class="">Easy Financing Options</li>
                                    <li class="">FREE Virtual Estimates</li>
                                </ul>

                                <hr/>
                                    <div class="row align-items-center p-3">
                                        <div class="col-md-4 col-12">
                                            <div class="text-center p-3">
                                                <Link to="/roof-repairs">
                                                    <picture>
                                                        <source type="image/webp" srcset="/fbm-data/images/roofing/roof-repairs.webp"/>
                                                            <img alt="Roof repairs" src="/fbm-data/images/roofing/roof-repairs.jpg" class=""/>
                                                    </picture>
                                                </Link>
                                            </div>
                                        </div>
                                        <div class="col-md-8 col-12">
                                            <h2 class="f-second text-secondary"><Link to="/roof-repairs">Roof Repairs</Link></h2>
                                            <p>
                                                When you need <Link to="/roof-repairs">roof repairs</Link>, Aspen Home Remodeling has got you covered. Whether you've got a leaky roof, roof damage, or need emergency repairs, the roofing pros at Aspen Home Remodeling offer quick response times, hands-on emergency services, and will have your repairs completed in no time.
                                            </p>
                                            <Link to="/roof-repairs" class="btn btn-more">Read More</Link>
                                        </div>
                                    </div>
                                    <hr/>
                                        <div class="row align-items-center p-3">
                                            <div class="col-md-4 col-12">
                                                <div class="text-center p-3">
                                                    <Link to="/roof-replacement">
                                                        <picture>
                                                            <source type="image/webp" srcset="/fbm-data/images/roofing/roof-replacement.webp"/>
                                                                <img alt="Roof replacement" src="/fbm-data/images/roofing/roof-replacement.jpg" class=""/>
                                                        </picture>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div class="col-md-8 col-12">
                                                <h2 class="f-second text-secondary"><Link to="/roof-replacement">Roof Replacement</Link></h2>
                                                <p>
                                                    If you've sustained roof damage, your roof is getting up in age, or perhaps you're just ready to enjoy a beautiful new roof on your River Edge home, the roofing professionals at Aspen Home Remodeling can offer you a number of design and color choices to give you the home of your dreams. We offer products from industry leaders that come with excellent warranties and offer a number of style choices to transform the look of your home.
                                                </p>
                                                <Link to="/roof-replacement" class="btn btn-more">Read More</Link>
                                            </div>
                                        </div>
                                        <hr/>
                                            <div class="row align-items-center p-3">
                                                <div class="col-md-4 col-12">
                                                    <div class="text-center p-3">
                                                        <Link to="/roof-types">
                                                            <picture>
                                                                <source type="image/webp" srcset="/fbm-data/images/roofing/roof-types.webp"/>
                                                                    <img alt="Roof types" src="/fbm-data/images/roofing/roof-types.jpg" class=""/>
                                                            </picture>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div class="col-md-8 col-12">
                                                    <h2 class="f-second text-secondary"><Link to="/roof-types">Roof Types</Link></h2>
                                                    <p>
                                                        At Aspen Home Remodeling, all of our roofing products are made in the USA by industry leaders you know and trust. Whether you're looking to balance style and budget with an asphalt, architectural, or dimensional shingle roof, or want the durability of metal roofing, the pros at Aspen Home Remodeling will guide you through our wide array of design choices to help give you the roof of your dreams!
                                                    </p>
                                                    <Link to="/roof-types" class="btn btn-more">Read More</Link>
                                                </div>
                                            </div>
                                            <hr/>

                                                <h2 class="">Roofing Solutions For Every Budget </h2>
                                                <p>
                                                    Aspen Home Remodeling offers our friends and neighbors in River Edge and the surrounding communities free virtual estimates and transparent and up-front pricing on every remodeling project. With our unbeatable prices and affordable finance options to meet any budget, our roofing professionals will make your home remodeling dreams a comfortable reality!
                                                </p>
                                                <h2 class=""> Hometown Home Remodeling Professionals </h2>
                                                <p>
                                                    Your local hometown remodeling specialists at Aspen Home Remodeling provide you with quality workmanship, professional results, and increased energy efficiency with all of our professional remodeling services, including:
                                                </p>
                                                <ul class="">
                                                    <li class="">Roof repairs &amp; roof replacement</li>
                                                    <li class="">Gutters, shutters, &amp; trim</li>
                                                    <li class="">Entry doors</li>
                                                    <li class="">James Hardie &amp; vinyl siding</li>
                                                    <li class="">Custom &amp; replacement windows</li>
                                                    <li class="">And more!</li>
                                                </ul>
                                                <p>
                                                    We don't just want to be your home remodeling professional, we want to be the remodeler you recommend to your friends, your neighbors, and your relatives!
                                                </p>
                            </div>
                        </div>
                    </div>
                    <FooterUpper/>
                </main>
        </div>
</div>
    );
}

export default Roofing;