import React from "react";
import {Link, withRouter} from "react-router-dom";
import {FooterUpper} from "./index";

class ArticleDetail extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            data: {}
        };
    }
    componentDidMount() {
        const id = this.props.match.params.article;
        this.getProject(id);
    }

    getProject = async (id) => {
        const sdata = {
            slug: id,
        };
        const data = await fetch("/.netlify/functions/get-article-details", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(sdata),
        })
            .then((res) => res.json())
            .catch((err) => console.error(err));
        this.setState({data})
    }
    render() {
        return (
            <div>
                <div id="banner">
                    <div class="bg-picture-container">
                        <picture>
                            <source media="(max-width:568px)" type="image/webp" srcset="/fbm-data/images/layout/banner/banner-interior-m.webp"/>
                                <source type="image/webp" srcset="/fbm-data/images/layout/banner/banner-interior.webp"/>
                                    <img src="/fbm-data/images/layout/banner/banner-interior.jpg" class="bg-picture-center" alt="remodeling company"/>
                        </picture>
                    </div>
                </div>
                <div id="page">
                    <main class="container-fluid" id="content">
                        <div class="row bg-white">
                            <div class="col-xl-8 col-lg-10 col-12 mx-auto p-xl-5 py-5">
                                <div class="p-0 p-lg-5">
                                    <div id="cms-breadcrumb" class="minimal-breadcrumb d-lg-flex d-none">
                                        <div class="trail">
                                            <Link title="Home" to="/">Home</Link>
                                        </div>

                                        <div class="trail">
                                            <Link title="Articles" to="/roofing-contractor-tips">Articles</Link>
                                        </div>

                                        <Link title="Roofing, Siding, & New Entry Door Project in New Milford, NJ"
                                              to={"/roofing-contractor-tips/"+this.state.data.slug}>{this.state.data.title}</Link>

                                    </div>
                                    <h1>{this.state.data.title}</h1>
                                    <div dangerouslySetInnerHTML= {{__html:this.state.data.content}}></div>
                                </div>
                            </div>
                        </div>
                        <FooterUpper/>
                    </main>
            </div>
            </div>
        );
    }
}
export default withRouter(ArticleDetail);