import React from "react";
import {Link, withRouter} from "react-router-dom";
import {FooterUpper} from "./index";

class RoofingContractorRamsey extends React.Component {


    render() {
        return (
            <div>
                <div id="banner">
                    <div className="bg-picture-container">
                        <picture>
                            <source media="(max-width:568px)" type="image/webp"
                                    srcSet="/fbm-data/images/layout/banner/banner-interior-m.webp"/>
                            <source type="image/webp" srcSet="/fbm-data/images/layout/banner/banner-interior.webp"/>
                            <img src="/fbm-data/images/layout/banner/banner-interior.jpg"
                                 className="bg-picture-center" alt="remodeling company"/>
                        </picture>
                    </div>
                </div>
                <div id="page">
                    <div className="container-fluid my-5">
                        <div className="row">
                            <div className="col-12 col-xl-8 mx-auto">
                                <div id="cms-breadcrumb" className="minimal-breadcrumb d-lg-flex d-none">
                                    <div className="trail">
                                        <Link title="Home" to="/">Home</Link>
                                    </div>

                                    <div className="trail">
                                        <Link title="Service Areas" to="/near-me">Service Areas</Link>
                                    </div>

                                    <div className="trail">
                                        <Link title="Ramsey, NJ" to="/near-me/roofing-contractor-ramsey">Ramsey,
                                            NJ</Link>
                                    </div>

                                </div>
                                <div id="content">
                                    <h1 className="">Ramsey's Leader in Roofing Contractor Services</h1>
                                    <picture>
                                        <source type="image/webp"
                                                srcSet="/fbm-data/images/cities/ramsey-replacement-windows.webp"/>
                                        <img alt="Ramsey replacement windows"
                                             src="/fbm-data/images/cities/ramsey-replacement-windows.jpg"
                                             className="img-r-dynamic"/>
                                    </picture>
                                    <p>
                                        <strong>Aspen Home Remodeling is the one to call when you're looking for a
                                            trustworthy, local roofing contractor for your Ramsey area home.</strong>
                                    </p>
                                    <ul className="">
                                        <li className="">25+ Years of Experience</li>
                                        <li className="">USA-Made Materials</li>
                                        <li className="">Energy Star®-Approved Products</li>
                                        <li className="">Limited Lifetime Warranties</li>
                                        <li className="">Easy Financing Options</li>
                                        <li className="">FREE Virtual Estimates</li>
                                    </ul>
                                    <p>
                                        We're the top-rated <a href="/">roofing contractor in Ramsey</a>, proudly
                                        serving a growing of satisfied roofing and remodeling customers in the
                                        communities around Ramsey with top-quality workmanship and exceptional results.
                                        Aspen Home Remodeling partners with many of the top-rated roofing manufacturers
                                        in the country to bring you stylish, durable, and dependable roofing materials,
                                        proudly made in the USA by industry leaders who stand behind their products.
                                    </p>
                                    <p>
                                        Whether you need a complete roof replacement or emergency roof repairs, you can
                                        trust Aspen Home Remodeling to keep you covered. We offer quick response times,
                                        hands-on emergency services, and professional results.
                                    </p>
                                    <h2 className=""> Styles, Colors, And Designs To Enhance Your Ramsey Home</h2>
                                    <p>
                                        At Aspen Home Remodeling, the roofing products we use are made in the USA by
                                        industry leaders and come with manufacturer's warranties, in addition to our
                                        standard labor warranty. If you're hoping to balance your budget with designer
                                        tastes, we offer a wide range of color, style, and design options to give you a
                                        high-end look. From our stylish architectural shingles to durable metal roofing,
                                        Aspen Home Remodeling offers a variety of roofing types to fit your needs.
                                    </p>
                                    <h2 className="">Ramsey's Reliable Hometown Roofing Contractor</h2>
                                    <p>
                                        New roofing can improve the look of your Ramsey home, lower your fuel costs, and
                                        increase your property's value, but it's important to trust your home to a
                                        roofing contractor you trust. We'll always treat your home with the same care
                                        and concern we treat our own, which is why we have so much repeat and referral
                                        business. Aspen Home Remodeling offers affordable options to meet any budget,
                                        transparent and up-front pricing, and easy financing to make your home
                                        remodeling dreams a comfortable reality….
                                    </p>
                                    <p>
                                        With over 25 years of experience in the home remodeling industry, the roofing
                                        pros at Aspen Home Remodeling don't just want to be your roofing contractor, we
                                        want to be the roofing contractor you tell your friends, neighbors, and
                                        relatives to call.
                                    </p>
                                    <h2 className=""> Window Replacement, Siding, And More To Transform Your Ramsey
                                        Home </h2>
                                    <p>
                                        When it comes to your home, we know what it means to you. Our customers
                                        frequently comment about our relationship-based service, the cleanliness of our
                                        job sites, and our courteous and reliable staff. We're here to make your home
                                        remodeling dreams come true by proudly offering a complete line of home
                                        remodeling services to transform your Ramsey home, including:
                                    </p>
                                    <ul>
                                        <li>Roof repair and roof replacement</li>
                                        <li><Link to="/james-hardie-siding">James Hardie siding</Link> and vinyl siding
                                        </li>
                                        <li>Custom, vinyl, and replacement windows</li>
                                        <li>Gutters, shutters, and trim</li>
                                        <li>Entry doors</li>
                                        <li>And more!!</li>
                                    </ul>
                                    <p>
                                        Home remodeling isn't just our job, it's in our DNA. We're your hometown
                                        remodeling and roofing contractor, and we're proud to provide you with quality
                                        workmanship on every job. Our home improvement services will help keep your
                                        house warmer, lower your fuel bills, and reduce outside noises so that you can
                                        fully enjoy the comfort of your Ramsey area home.
                                    </p>
                                    <p>
                                        When you're ready to make your home improvement vision a reality, call Aspen
                                        Home Remodeling at 201-994-5354 or fill out our online form and let us show give
                                        you the home of your dreams!
                                    </p>
                                    <h2>Latest Projects in Ramsey, NJ</h2>
                                    <div className="row mx-auto px-2 py-0">

                                        <div className="col-xl-4 col-md-6 col-12 mx-auto p-1">
                                            <div className="card h-100 project-cards">
                                                <picture>
                                                    <img
                                                        src="/fbm-data/images/projects/ramsey-ii-vinyl-siding-ramsey-nj-after.webp"
                                                        alt="Ramsey II Before & After Vinyl Siding in Ramsey, NJ"
                                                        className="card-img-top"/>
                                                </picture>
                                                <div className="card-body">
                                                    <div className="project-title">
                                                        <Link
                                                            to="/projects/ramsey-ii-before-after-vinyl-siding-in-ramsey-nj">Ramsey
                                                            II Before & After Vinyl Siding in Ramsey, NJ</Link>
                                                    </div>
                                                    <p className="my-2">Ramsey before and after vinyl siding project
                                                        consisting of removal of cedar shakes and providing insulated
                                                        vinyl siding, board and batten vinyl siding and new seamless
                                                        aluminum 5" gutters


                                                        Vinyl S [&hellip;]</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-4 col-md-6 col-12 mx-auto p-1">
                                            <div className="card h-100 project-cards">
                                                <picture>
                                                    <img
                                                        src="/fbm-data/images/projects/roofing-siding-new-entry-door-new-milford-nj-after.webp"
                                                        alt="Roofing, Siding, & New Entry Door Project in New Milford, NJ"
                                                        className="card-img-top"/>
                                                </picture>
                                                <div className="card-body">
                                                    <div className="project-title">
                                                        <Link
                                                            href="/projects/roofing-siding-new-entry-door-project-in-new-milford-nj">Roofing,
                                                            Siding, & New Entry Door Project in New Milford, NJ</Link>
                                                    </div>
                                                    <p className="my-2">Aspen Home Remodeling recently completed a
                                                        project of roof replacement, aluminum siding removal with new
                                                        vinyl siding, and a front entry door replacement.


                                                        Roof Replacement
                                                        Vinyl Siding
                                                        Entry D [&hellip;]</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-xl-8 mx-auto">
                                <div className="text-center">
                                    <div className="my-3">
                                        <a rel="noreferrer" className="btn btn-more" target="_blank" href="https://www.ramseynj.com/">Ramsey,
                                            New Jersey</a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-6 mx-auto">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe title="map" className="embed-responsive-item"
                                                    src="//maps.google.com/?q=Ramsey+New+Jersey&output=embed"></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="my-5">
                                    <div className="testimonials-container">
                                        <div className="row">
                                            <div className="col-12"><h2>Reviews & Testimonials on Aspen Home Remodeling
                                                in Ramsey, NJ</h2></div>
                                            <div className="w-100"></div>
                                            <div className="col-12"><p>
                                                There are currently no reviews or testimonials for <em>Ramsey, NJ</em>.
                                                Check back soon!
                                            </p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <FooterUpper/>
                    </div>
                </div>

            </div>
        );
    }
}

export default withRouter(RoofingContractorRamsey);
