import React from "react";
import { withRouter } from "react-router";
import {Link} from "react-router-dom";
import FooterUpper from "./FooterUpper";


class ProjectDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {}
        };
    }
    componentDidMount() {
        const id = this.props.match.params.project;
        this.getProject(id);
    }

    getProject = async (id) => {
        const sdata = {
            slug: id,
        };
        const data = await fetch("/.netlify/functions/get-project-details", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(sdata),
        })
            .then((res) => res.json())
            .catch((err) => console.error(err));
        this.setState({data})
    }
    render() {
        return (
            <div>
                <div id="banner">
                    <div className="bg-picture-container">
                        <picture>
                            <source media="(max-width:568px)" type="image/webp"
                                    srcSet="/fbm-data/images/layout/banner/banner-interior-m.webp"/>
                                <source type="image/webp" srcSet="/fbm-data/images/layout/banner/banner-interior.webp"/>
                                    <img src="/fbm-data/images/layout/banner/banner-interior.jpg"
                                         className="bg-picture-center" alt="remodeling company"/>
                        </picture>
                    </div>
                </div>
                <div id="page">
                    <main className="container-fluid" id="content">
                        <div className="row bg-white">
                            <div className="col-xl-8 col-lg-10 col-12 mx-auto p-xl-5 py-5">
                                <div className="p-0 p-lg-5">
                                    <div id="cms-breadcrumb" className="minimal-breadcrumb d-lg-flex d-none">
                                        <div className="trail">
                                            <Link title="Home" to="/">Home</Link>
                                        </div>

                                        <div className="trail">
                                            <Link title="Projects" to="/projects">Projects</Link>
                                        </div>

                                        <div className="trail">
                                            <Link title="Roofing, Siding, & New Entry Door Project in New Milford, NJ"
                                               to={"/projects/"+this.state.data.slug}>{this.state.data.title}</Link>
                                        </div>

                                    </div>
                                    <h1>{this.state.data.title}</h1>
                                    <div dangerouslySetInnerHTML= {{__html:this.state.data.content}}></div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-8 col-lg-10 col-12 mx-auto px-xl-5">
                                <div className="p-0 px-lg-5">
                                    <div className="row pb-5">
                                        <div className="col-xl-6 mx-auto">
                                            <div className="embed-responsive embed-responsive-16by9">
                                                <iframe title="map" className="embed-responsive-item"
                                                        src={"//maps.google.com/?q="+this.state.data.iframe}></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <FooterUpper/>
                    </main>
                </div>
            </div>
        );
    }
}
export default withRouter(ProjectDetail);