import React from "react";
import {Link, withRouter} from "react-router-dom";
import {FooterUpper} from "./index";

class RoofingContractorHackensack extends React.Component{
    render() {
        return (
            <div>
                <div id="banner">
                    <div className="bg-picture-container">
                        <picture>
                            <source media="(max-width:568px)" type="image/webp"
                                    srcSet="/fbm-data/images/layout/banner/banner-interior-m.webp"/>
                                <source type="image/webp" srcSet="/fbm-data/images/layout/banner/banner-interior.webp"/>
                                    <img src="/fbm-data/images/layout/banner/banner-interior.jpg"
                                         className="bg-picture-center" alt="remodeling company"/>
                        </picture>
                    </div>
                </div>
                <div id="page">
                    <div className="container-fluid my-5">
                        <div className="row">
                            <div className="col-12 col-xl-8 mx-auto">
                                <div id="cms-breadcrumb" className="minimal-breadcrumb d-lg-flex d-none">
                                    <div className="trail">
                                        <Link title="Home" to="/">Home</Link>
                                    </div>

                                    <div className="trail">
                                        <Link title="Service Areas" to="/near-me">Service Areas</Link>
                                    </div>

                                    <div className="trail">
                                        <Link title="Hackensack, NJ" to="/near-me/roofing-contractor-hackensack">Hackensack,
                                            NJ</Link>
                                    </div>

                                </div>
                                <div id="content">
                                    <h1 className="">Your Local Roofing Contractor Company in Hackensack, NJ</h1>
                                    <picture>
                                        <source type="image/webp"
                                                srcSet="/fbm-data/images/cities/hackensack-replacement-windows.webp"/>
                                            <img alt="Hackensack replacement windows"
                                                 src="/fbm-data/images/cities/hackensack-replacement-windows.jpg"
                                                 className="img-r-dynamic"/>
                                    </picture>
                                    <p>
                                        At Aspen Home Remodeling, we're northeastern New Jersey's leading roofing
                                        contractor, proudly offering a wide variety of roofing services for your
                                        Hackensack area home. Our roof replacement and roof repair materials are made in
                                        the USA by leaders in the roofing industry and are installed by skilled local
                                        professionals you know and trust. We offer a huge selection of design, color,
                                        and style choices to enhance the appeal of your Hackensack home and make your
                                        home remodeling vision a reality.
                                    </p>
                                    <p>
                                        Our skilled roofing professionals will guide you through the process to choose
                                        the <Link to="/roof-types">roof types</Link> and colors that work with your roof's
                                        shape and slope while fully complementing the exterior of your Hackensack home.
                                        Whether you're considering simple 3-tab shingles, trendy dimensional shingles,
                                        or a durable and classic metal roofing system, we'll help you make a selection
                                        that will bring out your roof's features while providing you with added
                                        efficiency, lower fuel costs, and noise reduction. Our roofing specialists bring
                                        over a quarter-century of experience and a commitment to excellence to every
                                        roofing job that can't be beat.
                                    </p>
                                    <p>
                                        Aspen Home Remodeling is the roofing contractor you can trust to transform your
                                        Hackensack area home into the home of your dreams!
                                    </p>
                                    <h2 className=""> Highlight Your Hackensack Home With Help From Your Hometown
                                        Roofing Contractor </h2>
                                    <p>
                                        A new roof can really highlight the look of your Hackensack home, giving it a
                                        high-end appeal and a designer style. With today's variety of enhanced
                                        energy-efficient and long-lasting roofing materials, your new roof may provide
                                        you with a number of unexpected benefits, including:
                                    </p>
                                    <ul className="">
                                        <li className="">Elevated curb appeal</li>
                                        <li className="">Reduction of heat loss</li>
                                        <li className="">Lower fuel costs</li>
                                        <li className="">Enhanced outside noise reduction</li>
                                        <li className="">Improved property value</li>
                                    </ul>

                                    <p>
                                        <strong>Your roof is your home's crowning glory, so treat your Hackensack home
                                            like the castle that it is and call the roofing contractor your friends and
                                            neighbors in Hackensack and the surrounding communities trust for all of
                                            their home remodeling needs - Aspen Home Remodeling.</strong>
                                    </p>
                                    <h2 className="">From Roofing To Replacement Windows To Siding - We're Your Local
                                        Hackensack Remodeling Contractor</h2>
                                    <p>
                                        At Aspen Home Remodeling, we live and work here, too. We understand what your
                                        home means to you, which is why you can count on us to always treat your home
                                        with the same care and respect that we'd treat our own. We proudly offer:
                                    </p>
                                    <ul className="">
                                        <li className="">25+ Years of Experience</li>
                                        <li className="">USA-Made Materials</li>
                                        <li className="">Energy Star®-Approved Products</li>
                                        <li className="">Limited Lifetime Warranties</li>
                                        <li className="">Easy Financing Options</li>
                                        <li className="">FREE Virtual Estimates</li>
                                    </ul>
                                    <p>
                                        We're the remodeling and <a href="/">roofing contractor in Hackensack</a> that
                                        your friends and neighbors in the northeast New Jersey region know and trust for
                                        all of their home remodeling and roofing needs. If you're ready to upgrade the
                                        look of your starter home with new vinyl siding, are looking to lower the
                                        heating bills in your established home with energy-efficient windows, or re
                                        ready to finally take on your roof replacement, the home improvement pros at
                                        Aspen Home Remodeling are here to make your vision a reality.
                                    </p>
                                    <p>
                                        We work with top-rated manufacturers in the home improvement industry to deliver
                                        high-quality, durable materials that are made in the USA. Our products are
                                        verified by industry oversight entities like the Vinyl Siding Institute and
                                        rated as Energy Star®-Approved Products and come with full manufacturer
                                        warranties - and we provide warranty on all of our workmanship, so you'll feel
                                        confident that you'll always have top-quality results on every project.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-xl-8 mx-auto">
                                <div className="text-center">
                                    <div className="my-3">
                                        <a rel="noreferrer" className="btn btn-more" target="_blank" href="http://www.hackensack.org/">Hackensack,
                                            New Jersey</a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-6 mx-auto">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe title="map" className="embed-responsive-item"
                                                    src="//maps.google.com/?q=Hackensack+New+Jersey&output=embed"></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="my-5">
                                    <div className="testimonials-container">
                                        <div className="row">
                                            <div className="col-12"><h2>Reviews & Testimonials on Aspen Home Remodeling
                                                in Hackensack, NJ</h2></div>
                                            <div className="w-100"></div>
                                            <div className="col-12"><p>
                                                There are currently no reviews or testimonials for <em>Hackensack,
                                                NJ</em>. Check back soon!
                                            </p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <FooterUpper/>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(RoofingContractorHackensack);
